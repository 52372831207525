import React, { useState, useEffect } from "react";
import { sortObjectsBy } from "../../helpers/helpers";
import CustomerIcon from "../../assets/flag-icons/sidebar-customers.svg";
import AssignedToIcon from "../../assets/flag-icons/assignedToIcon.svg";
import FactoryIcon from "../../assets/flag-icons/sidebar-factories.svg";
import DateIcon from "../../assets/flag-icons/sidebar-week.svg";
import { GeneralFilterContainer } from "./styles";
import SelectFilter from "./SelectFilter";
import { useUser, useCompanyUsers } from "../../hooks/user";
import { useTags } from "../../hooks/tags";
import {
  useIsAllowedFunction,
  checkSpecificPermissions,
} from "../../hooks/permissions";
import MultiSelectFilter from "./MultiSelectFilter";
import userTypes from "../../api/types/userTypes";
import { useFactories } from "../../hooks/factories";
import { useCustomers } from "../../hooks/customers";
import {
  GENERAL_PERMISSION_VALUE,
  TYPE_OF_TAGS,
} from "../../helpers/constants";
import { useGLAccountItems } from "../../hooks";
import {
  ExpensesIcon,
  PaidToIcon,
  TagLabelIcon,
} from "../../helpers/iconSvgPath";
import { getListOptions } from "../PurchaseOrderDashboard/HelperProjectTasks";

function GeneralFilters({
  dateFields = [
    "PO Date",
    "SO Required Delivery Date",
    "SO Revised Delivery Date",
    "PO Required Delivery Date",
    "PO Revised Delivery Date",
    "PO Required Ship Date",
    "PO Revised Ship Date",
  ],
  onChange = () => {},
  currentFilters = {
    customer: [],
    factory: [],
    dateRange: { start: "", end: "" },
  },
  enabledFields = { customer: true, factory: true, date: true, tag: false },
  onOpenDatePicker,
  extraField = null,
  tagFilters = [],
  filterData = "type",
  handleListOpen = () => {},
  paidToOptions = { list: [] },
  notificationStyles = false,
}) {
  const isAllowed = useIsAllowedFunction();
  const user = useUser();
  const [filters, setFilters] = useState(currentFilters);
  const [currentList, setCurrentList] = useState("");
  const [changedFilters, setChangedFilters] = useState(false);
  const showInactive = false;
  const customers = useCustomers(showInactive);
  const factories = useFactories(showInactive);
  const GLAccountItems = useGLAccountItems(true);
  const tags = useTags();
  const companyUsers = useCompanyUsers({
    showBotUser: false,
    showInactiveUsers: false,
  });

  useEffect(() => {
    setFilters({ ...currentFilters });
    setChangedFilters(false);
  }, [currentFilters]);

  function onChangeFilter(filter, value) {
    const newFilters = { ...filters, [filter]: value };
    setFilters(newFilters);
    setChangedFilters(true);
    onChange(newFilters);
  }

  const paidList = paidToOptions.list || [];

  function getLabelListOptions({ allList = [] }) {
    const AUTOMATIC_SYSTEM_LABELS = [
      TYPE_OF_TAGS.REGULAR_TAG,
      TYPE_OF_TAGS.MISMATCH_TAG,
      TYPE_OF_TAGS.FILE_TAG,
      TYPE_OF_TAGS.DEADLINE_TAG,
      TYPE_OF_TAGS.EXPENSES_TAG,
    ];
    const allTags = [];
    AUTOMATIC_SYSTEM_LABELS.forEach((type) => {
      const result = allList
        .filter((label) => label.type === type)
        .map((label) => {
          let styles = {};
          if (label.type !== TYPE_OF_TAGS.REGULAR_TAG) {
            styles = {
              fontStyle: "italic",
            };
          }
          return {
            value: label.id,
            label: label.name,
            type: label.type,
            description: label.description || "",
            styles: styles,
          };
        })
        .sort(sortObjectsBy("label", false));
      allTags.push(...result);
    });
    return allTags;
  }

  return (
    <GeneralFilterContainer className="filterContainer">
      {enabledFields.assignedTo && (
        <MultiSelectFilter
          buttonId="list-view-select-filter-assignedto"
          value={getListOptions({
            filterList: filters.assignedTo,
            allList: companyUsers,
            key: {
              value: "id",
              label: "displayName",
              avatar: true,
            },
            prefix: "@",
          })}
          options={getListOptions({
            filterList: filters.assignedTo,
            allList: companyUsers,
            key: {
              value: "id",
              label: "displayName",
              avatar: true,
            },
            prefix: "@",
            showAll: true,
          })}
          label="Assigned To"
          icon={AssignedToIcon}
          onChange={(field) => onChangeFilter("assignedTo", field)}
          onOpenList={(value) => handleListOpen(value)}
          currentList={currentList}
          setCurrentList={setCurrentList}
          onClose={() => {
            if (changedFilters) {
              onChange(filters);
            }
          }}
          scope="users"
          placeholder="Search for person"
          notificationStyles={notificationStyles}
        />
      )}
      {enabledFields.customer && (
        <MultiSelectFilter
          buttonId="list-view-select-filter-customer"
          value={getListOptions({
            filterList: filters.customer,
            allList: customers,
          })}
          label="Customer"
          icon={CustomerIcon}
          onChange={(field) => onChangeFilter("customer", field)}
          onOpenList={(value) => handleListOpen(value)}
          currentList={currentList}
          setCurrentList={setCurrentList}
          options={getListOptions({
            filterList: filters.customer,
            allList: customers.filter(
              (customer) =>
                user.role === userTypes.SUPER_ADMIN ||
                user.role === userTypes.TRADEDASH_EMPLOYEE ||
                checkSpecificPermissions({
                  userPermissions: user.permissions,
                  permissionToCheck: [
                    customer.id,
                    GENERAL_PERMISSION_VALUE.ALL_CUSTOMERS,
                  ],
                })
            ),
            showAll: true,
          })}
          onClose={() => {
            if (changedFilters) {
              onChange(filters);
            }
          }}
          scope="customers"
          placeholder="Search for customer"
          notificationStyles={notificationStyles}
        />
      )}
      {enabledFields.factory && (
        <MultiSelectFilter
          buttonId="list-view-select-filter-factory"
          value={getListOptions({
            filterList: filters.factory,
            allList: factories,
          })}
          options={getListOptions({
            filterList: filters.factory,
            allList: factories.filter(
              (factory) =>
                user.role === userTypes.SUPER_ADMIN ||
                user.role === userTypes.TRADEDASH_EMPLOYEE ||
                checkSpecificPermissions({
                  userPermissions: user.permissions,
                  permissionToCheck: [
                    factory.id,
                    GENERAL_PERMISSION_VALUE.ALL_VENDORS,
                  ],
                })
            ),
            showAll: true,
          })}
          label="Vendor"
          currentList={currentList}
          setCurrentList={setCurrentList}
          icon={FactoryIcon}
          onChange={(field) => onChangeFilter("factory", field)}
          onOpenList={(value) => handleListOpen(value)}
          onClose={() => {
            if (changedFilters) {
              onChange(filters);
            }
          }}
          scope="vendors"
          placeholder="Search for vendor"
          notificationStyles={notificationStyles}
        />
      )}
      {isAllowed("see_tags") && enabledFields.tag && (
        <MultiSelectFilter
          buttonId="list-view-select-filter-label"
          value={getListOptions({
            filterList: filters.tag,
            allList: tags,
            sorted: "type",
          })}
          options={getLabelListOptions({
            allList: tags.filter((option) =>
              tagFilters.length > 0
                ? !tagFilters.includes(option[filterData])
                : true
            ),
          })}
          label="Label"
          icon={<TagLabelIcon width={16} height={19} />}
          isIconComponent
          currentList={currentList}
          setCurrentList={setCurrentList}
          onOpenList={(value) => handleListOpen(value)}
          onChange={(field) => onChangeFilter("tag", field)}
          onClose={() => {}}
          scope="tags"
          classType={"Label"}
          placeholder="Search for label"
          notificationStyles={notificationStyles}
          maxWidth={350}
          showInfo
        />
      )}

      {enabledFields.paidTo && (
        <MultiSelectFilter
          buttonId="list-view-select-filter-paidto"
          value={getListOptions({
            filterList: filters.paidTo,
            allList: paidList.map((item) => ({
              id: item,
              name: item,
            })),
          })}
          label="Supplier"
          currentList={currentList}
          setCurrentList={setCurrentList}
          icon={<PaidToIcon height={19} width={"100%"} />}
          isIconComponent
          onChange={(field) => onChangeFilter("paidTo", field)}
          onOpenList={(value) => handleListOpen(value)}
          options={getListOptions({
            filterList: filters.paidTo,
            allList: paidList.map((item) => ({
              id: item,
              name: item,
            })),

            showAll: true,
            key: {
              value: "id",
              label: "name",
              avatar: false,
            },
          })}
          onClose={() => {
            if (changedFilters) {
              onChange(filters);
            }
          }}
          scope="paidTo"
          placeholder="Search for supplier"
          notificationStyles={notificationStyles}
        />
      )}
      {enabledFields.glAccount && (
        <MultiSelectFilter
          buttonId="list-view-select-filter-glaccount"
          value={getListOptions({
            filterList: filters.glAccount,
            allList: GLAccountItems,
          })}
          options={getListOptions({
            filterList: filters.glAccount,
            allList: GLAccountItems.map((option) => ({
              id: option.id,
              name: `${option.name}-${option.description}`,
            })),
            showAll: true,
          })}
          label="G/L Account"
          currentList={currentList}
          setCurrentList={setCurrentList}
          icon={<ExpensesIcon height={19} width={"100%"} />}
          isIconComponent
          onChange={(field) => onChangeFilter("glAccount", field)}
          onOpenList={(value) => handleListOpen(value)}
          onClose={() => {
            if (changedFilters) {
              onChange(filters);
            }
          }}
          scope="glAccount"
          placeholder="Search for G/L Account"
          notificationStyles={notificationStyles}
        />
      )}
      {enabledFields.date && (
        <SelectFilter
          buttonId="list-view-select-filter-date"
          value={filters.date || ""}
          onOpenList={(value) => handleListOpen(value)}
          onChange={(field) => {
            onChangeFilter("date", field);
            onChangeFilter("dateRange", { start: "", end: "" });
            onOpenDatePicker(field);
          }}
          options={dateFields.map((field) => ({ value: field, label: field }))}
          label="Date"
          icon={DateIcon}
          currentList={currentList}
          setCurrentList={setCurrentList}
          closeAfterSelect
          notificationStyles={notificationStyles}
        />
      )}

      {extraField && extraField}
    </GeneralFilterContainer>
  );
}

export default GeneralFilters;
