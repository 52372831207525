import React from "react";
import { colors } from "../../assets/jss/variables";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import TooltipTD from "../../components/Tooltip/TooltipTD";
import { PO_DIAMOND_STATUS, PO_STATUS, TOOLTIP_TYPE } from "../constants";
import { DiamondIcon, StarIcon } from "../iconSvgPath";
import { orderType } from "../salesOrder";
import { cx } from "@emotion/css";
import { StarStyled } from "../../components/filterTableRefactor/MainRowStyled";

export const getDiamondStatus = ({
  diamondStatus,
  itemStatus,
  style = {},
  className = "",
}) => {
  if (itemStatus === PO_STATUS.VOIDED) {
    return <span className={className} style={{ width: 10, ...style }} />;
  } else {
    if (diamondStatus === PO_DIAMOND_STATUS.LATE) {
      return (
        <TooltipTD
          label="There are one ore more late tasks"
          type={TOOLTIP_TYPE.DIAMOND}
          style={{ height: 18, width: 10, display: "flex", ...style }}
          className={className}
        >
          <DiamondIcon color={colors.diamondRed} height={18} width={10} />
        </TooltipTD>
      );
    } else if (diamondStatus === PO_DIAMOND_STATUS.NEAR_DUE) {
      return (
        <TooltipTD
          label="There are one ore more tasks due today or tomorrow"
          type={TOOLTIP_TYPE.DIAMOND}
          style={{ height: 18, width: 10, display: "flex", ...style }}
          className={className}
        >
          <DiamondIcon color={colors.diamondOrange} height={18} width={10} />
        </TooltipTD>
      );
    }
    return <span className={className} style={{ width: 10, ...style }} />;
  }
};

export const getStarFavorite = ({
  favorites = [],
  itemId,
  removeFavorite = () => {},
  addFavorite = () => {},
  height = 17,
  width = 16,
}) => {
  const exist = favorites.includes(itemId);
  return (
    <StarStyled
      className={cx("starContainer", {
        "favorite-active-star": exist,
        "favorite-inactive-star": !exist,
      })}
    >
      <StarIcon
        width={width}
        height={height}
        onClick={exist ? removeFavorite : addFavorite}
        svgClassname={"svgIcon"}
      />
    </StarStyled>
  );
};

export const getDisclouserArrow = ({
  currentSetup = {},
  shipments = [],
  purchaseOrders = [],
  salesOrders = [],
  items = [],
  type = orderType.SALES_ORDER,
  handleChangeSetup = () => {},
  min = 1,
  forceOpen
}) => {
  let show, rowOpen;
  switch (type) {
    case orderType.SALES_ORDER:
      show = shipments.length > 1 || purchaseOrders.length > 1;
      rowOpen = currentSetup.shipments || currentSetup.POs;
      break;
    case orderType.PURCHASE_ORDER:
      show = shipments.length > 1 || salesOrders.length > 1;
      rowOpen = currentSetup.shipments || currentSetup.SOs;
      break;
    case orderType.SHIPMENT:
      show = salesOrders.length > 1 || purchaseOrders.length > 1;
      rowOpen = currentSetup.POs || currentSetup.SOs;
      break;
    default:
      show = items.length > min;
      rowOpen = typeof currentSetup === "boolean" ? currentSetup : false;
      break;
  }
  if (forceOpen) {
    rowOpen = true;
  }
  if (show) {
    return (
      <div onClick={handleChangeSetup} className="disclouserArrowStyle">
        <KeyboardArrowDownIcon
          className="arrow"
          style={{
            transform: !rowOpen ? "rotate(-90deg)" : "none",
          }}
        />
      </div>
    );
  } else {
    return <div style={{ width: 20 }} />;
  }
};
