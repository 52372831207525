import React, { useCallback } from "react";
import {
  getTagsLabel,
  getTheDate,
  isTradeDashEmployee,
  getProgressValue,
  getActivitiesNotes,
  getDashboardSearchPath,
} from "../../helpers/helpers";
import { getShipmentBadge } from "../../helpers/shipments";
import { NavLink } from "react-router-dom";
import { removeFavorite, addFavorite } from "../../helpers/favoritesHelper";
import TooltipTD from "../../components/Tooltip/TooltipTD";
import {
  generalPermissions,
  GENERAL_PERMISSION_VALUE,
  headerColumns,
  notAllowedToSee,
  SHIPMENT_STATUS,
  BACKDROP_TYPE,
} from "../../helpers/constants";
import TagsModal from "../../components/Modal/TagsModal";
import userTypes from "../../api/types/userTypes";
import { dbTables } from "../../api/types/dbTables";
import { useDispatch } from "react-redux";
import {
  setBackdropPanelSection,
  setGlobalNoteDate,
} from "../../actions/DataActions";

import { getTagStatus, orderType, zeroIsBlank } from "../../helpers/salesOrder";
import {
  checkSpecificPermissions,
  verifyPermission,
} from "../../hooks/permissions";
import { useFactories } from "../../hooks/factories";
import { ORDER_DASHBOARD_ACTUAL_VIEWS } from "../../components/PurchaseOrderDashboard/sections/helpers";
import SalesOrdersItem from "./SalesOrderItem";
import POsDetailItem from "../SalesOrdersScreen/PODetailsItems";
import { TableCell } from "@mui/material";
import { CircularStaticProgress } from "../../components/CircularProgress/CircularStaticProgress";
import { cx } from "@emotion/css";
import { shipmentBadgeClass } from "../StyledComponent/BadgeStyled";
import {
  getDiamondStatus,
  getDisclouserArrow,
  getStarFavorite,
} from "../../helpers/ScreenComponetHelper/componetHelper";
import {
  MainRowStyled,
  mainRowClasses,
} from "../../components/filterTableRefactor/MainRowStyled";
import ThreePanelButton from "../../components/Buttons/ThreePanelButton";
import { useUserMentions } from "../../hooks/user";

function ShipmentsTableRow({
  item,
  favorites,
  user,
  isAllowed = () => {},
  customers,
  headers = [],
  tags,
  onUpdateItem,
  isThereColumnSetting = false,
  filters = { factory: [] },
  onChangeSetupStorage = () => {},
  shipmentScreenSetup = {},
  favoritesDB = [],
  nonLookUpfilters = {},
}) {
  const filterTag = filters.tag || [];
  const isVoided = item.status === SHIPMENT_STATUS.VOIDED;
  const customer = customers.find((cus) => cus.id === item.customerId);
  const factories = useFactories();
  const dispatch = useDispatch();
  const shipmentMentions = useUserMentions(dbTables.SHIPMENTS);

  const setBackdrop = (data) => {
    setBackdropPanelSection({ ...data, dispatch });
  };

  const setNoteDate = useCallback(({ type, creationDate, noteId }) =>
    setGlobalNoteDate({
      type,
      creationDate,
      noteId,
    })(dispatch)
  );

  function handleChangeShipmentSetup() {
    const shipmentSetup = shipmentScreenSetup[item.id] || {};
    if (shipmentSetup.POs || shipmentSetup.SOs) {
      onChangeSetupStorage({ item, poValue: false, soValue: false });
    } else {
      onChangeSetupStorage({ item, poValue: true, soValue: true });
    }
  }

  function shipmentTableRowSelector({ column, enabled, index }) {
    const purchaseOrders = item.purchaseOrders || [];
    const filterPurchaseOrders = purchaseOrders.filter((po) =>
      verifyPermission({
        user: user,
        permissionToCheck: [po.factoryId, GENERAL_PERMISSION_VALUE.ALL_VENDORS],
      })
    );
    switch (column) {
      case headerColumns.FAVORITE:
        return (
          <TableCell key={index} className="favorite">
            {getDisclouserArrow({
              currentSetup: shipmentScreenSetup[item.id],
              handleChangeSetup: handleChangeShipmentSetup,
              purchaseOrders: item.salesOrders,
              salesOrders: filterPurchaseOrders,
              type: orderType.SHIPMENT,

            })}
          </TableCell>
        );

      case headerColumns.DIAMOND:
        return (
          <TableCell key={index} className={"diamond"}>
            <div
              style={{
                display: "flex",
                gap: 16,
                alignItems: "center",
              }}
            >
              {getStarFavorite({
                favorites: favorites,
                itemId: item.id,
                removeFavorite: () =>
                  removeFavorite({
                    favoritesDB,
                    id: item.id,
                  }),
                addFavorite: () =>
                  addFavorite({
                    id: item.id,
                    user,
                    favoritesDB,
                    dbTable: dbTables.SHIPMENT_FAVORITES,
                  }),
              })}
              {getDiamondStatus({
                diamondStatus: item.diamondStatus,
                itemStatus: item.status,
              })}
            </div>
          </TableCell>
        );

      case headerColumns.PROGRESS:
        return isVoided ? (
          <TableCell key={index} />
        ) : (
          <TableCell key={index} className="circularProgress">
            <CircularStaticProgress
              label={`${item.completedTasks}/${item.totalTasks} Tasks`}
              value={+getProgressValue(item)}
              thickness={16}
              style={{
                transform: "rotate(270deg)",
                color: "#39F",
              }}
              size={18}
            />
          </TableCell>
        );

      case headerColumns.MENTIONS:
        const [poItem = {}] = filterPurchaseOrders;
        const [soItem = {}] = item.salesOrders;
        return (
          <TableCell key={column + index} className={"mention"}>
            {shipmentMentions[item.id] > 0 && (
              <TooltipTD label={"Shipment notes only"}>
                <NavLink
                  to={getDashboardSearchPath({
                    salesOrderId: soItem.id,
                    purchaseOrderId: poItem.id,
                    shipmentId: item.id,
                    section: ORDER_DASHBOARD_ACTUAL_VIEWS.THREE_PANEL_SECTION,
                  })}
                  onClick={async () => {
                    const shipmentActivity = await getActivitiesNotes({
                      companyId: item.companyId,
                      orderId: item.id,
                      userId: user.id,
                      orderTable: dbTables.SHIPMENTS,
                      dispatch: dispatch,
                    });
                    if (shipmentActivity) {
                      setNoteDate({
                        type: dbTables.SHIPMENTS,
                        creationDate: shipmentActivity.creationDate,
                        noteId: shipmentActivity.id,
                      });
                    }
                    setBackdrop({
                      type: BACKDROP_TYPE.BADGE_NUMBER,
                      id: BACKDROP_TYPE.BADGE_NUMBER,
                    });
                  }}
                >
                  <span style={{ textDecoration: "underline" }}>
                    {shipmentMentions[item.id]}
                  </span>
                </NavLink>
              </TooltipTD>
            )}
          </TableCell>
        );

      case headerColumns.SHIPMENT:
        const [pursaseOrderItem = {}] = filterPurchaseOrders;
        const [salesOrderItem = {}] = item.salesOrders || [];
        const shipmentTags = item.tags || [];
        return (
          enabled && (
            <TableCell key={index} className={"number"}>
              <div className="number-table-container">
                {isAllowed("see_tags") && (
                  <TagsModal
                    key={item.id}
                    label={getTagsLabel(tags, shipmentTags).tagsString}
                    tags={getTagsLabel(tags, shipmentTags).tagsArray}
                    allTags={tags}
                    item={item}
                    currentUser={user}
                    tagReference={dbTables.SHIPMENTS}
                    onUpdateItem={onUpdateItem}
                    isThereItemRef={false}
                    isReadOnly={isTradeDashEmployee(user)}
                    status={getTagStatus({
                      tags: shipmentTags,
                      currentTags: filterTag,
                    })}
                  />
                )}
                <NavLink
                  to={
                    isVoided
                      ? false
                      : getDashboardSearchPath({
                          salesOrderId: salesOrderItem.id,
                          purchaseOrderId: pursaseOrderItem.id,
                          shipmentId: item.id,
                          section: ORDER_DASHBOARD_ACTUAL_VIEWS.SHIPMENT,
                        })
                  }
                  style={{
                    overflow: "visible",
                    marginLeft: 0,
                    textDecoration: "none",
                  }}
                  id="list-view-content-shipment"
                >
                  {getShipmentBadge({
                    shipment: item,
                    classes: shipmentBadgeClass.medium,
                  })}
                </NavLink>
                <ThreePanelButton
                  soId={salesOrderItem.id}
                  poId={pursaseOrderItem.id}
                  shipmentId={item.id}
                  tab={2}
                />
              </div>
            </TableCell>
          )
        );

      case headerColumns.SALES_ORDER:
        return (
          enabled && (
            <TableCell key={index} className={"number"}>
              <SalesOrdersItem
                item={{ ...item, purchaseOrders: filterPurchaseOrders }}
                user={user}
                filters={filters}
                isAllowed={isAllowed}
                isTradeDashEmployee={isTradeDashEmployee}
                onUpdateItem={onUpdateItem}
                tags={tags}
                salesOrderSetup={shipmentScreenSetup || {}}
                classContainer="SOs-container"
                onChangeSalesOrderSetup={() => {
                  const itemRow = shipmentScreenSetup[item.id] || {};
                  onChangeSetupStorage({
                    item,
                    soValue: !itemRow.SOs,
                  });
                }}
                query={nonLookUpfilters.query}
                iconStyle={{ paddingLeft: 25 }}
              />
            </TableCell>
          )
        );

      case headerColumns.PO:
        return (
          enabled && (
            <TableCell key={index} className="poNumber">
              <POsDetailItem
                item={item}
                user={user}
                filters={filters}
                isAllowed={isAllowed}
                isTradeDashEmployee={isTradeDashEmployee}
                onUpdateItem={onUpdateItem}
                tags={tags}
                salesOrderSetup={shipmentScreenSetup || {}}
                factories={factories}
                onChangeSalesOrderSetup={() => {
                  const itemRow = shipmentScreenSetup[item.id] || {};
                  onChangeSetupStorage({
                    item,
                    poValue: !itemRow.POs,
                  });
                }}
                query={nonLookUpfilters.query}
              />
            </TableCell>
          )
        );

      case headerColumns.CUSTOMER:
        return (
          enabled &&
          customer && (
            <TableCell key={index} className={"customerName"}>
              <TooltipTD label={customer.name} className="customized-tooltip">
                <NavLink
                  to={
                    !(
                      user.role === userTypes.SUPER_ADMIN ||
                      user.role === userTypes.TRADEDASH_EMPLOYEE ||
                      checkSpecificPermissions({
                        userPermissions: user.permissions,
                        permissionToCheck: [
                          item.customerId,
                          GENERAL_PERMISSION_VALUE.ALL_CUSTOMERS,
                        ],
                      })
                    )
                      ? "#"
                      : `/app/customers/${item.customerId}`
                  }
                  className="navLink"
                  id="list-view-content-customer"
                >
                  <span className="span-description">{customer.name}</span>
                </NavLink>
              </TooltipTD>
            </TableCell>
          )
        );

      case headerColumns.CREATION_DATE:
        return (
          enabled && (
            <TableCell key={index} className={"date"} id="createDate">
              {getTheDate(item.creationDate, "M/D/YY")}
            </TableCell>
          )
        );

      case headerColumns.SHIP_DATE:
        return (
          enabled && (
            <TableCell key={index} className={"date"} id="shipDate">
              {getTheDate(item.shipDate, "M/D/YY")}
            </TableCell>
          )
        );

      case headerColumns.DELIVERY_DATE:
        return (
          enabled && (
            <TableCell key={index} className={"date"} id="deliveryDate">
              {getTheDate(item.deliveryDate, "M/D/YY")}
            </TableCell>
          )
        );

      case headerColumns.UNIQUE_ITEMS:
        return (
          enabled && (
            <TableCell key={index} className={"CBM"} id="uniqueItems">
              {item.uniqueItems || ""}
            </TableCell>
          )
        );
      case headerColumns.SALES_INVOICE:
        return (
          enabled && (
            <TableCell key={index} className={"customerName"}>
              <TooltipTD label={item.invoice} className="customized-tooltip">
                <span>{item.invoice}</span>
              </TooltipTD>
            </TableCell>
          )
        );

      case headerColumns.BOL:
        return (
          enabled && (
            <TableCell key={index} className={"customerName"}>
              <TooltipTD
                label={item.billOfLading}
                className="customized-tooltip"
              >
                <span>{item.billOfLading}</span>
              </TooltipTD>
            </TableCell>
          )
        );

      case headerColumns.CBM:
        return (
          enabled && (
            <TableCell key={index} className="CBM" id="CBM">
              {zeroIsBlank(item.CBM, true)}
            </TableCell>
          )
        );

      case headerColumns.SHIPPING_TERMS:
        return (
          enabled && (
            <TableCell key={index} className="customerName">
              {item.shippingTerms}
            </TableCell>
          )
        );

      case headerColumns.LOADING_PORT:
        return (
          enabled && (
            <TableCell key={index} className={"customerName"}>
              <TooltipTD
                label={item.loadingPort}
                className="customized-tooltip"
              >
                <span>{item.loadingPort}</span>
              </TooltipTD>
            </TableCell>
          )
        );
      case headerColumns.UNLOADING_PORT:
        return (
          enabled && (
            <TableCell key={index} className={"customerName"}>
              <TooltipTD
                label={item.unloadingPort}
                className="customized-tooltip"
              >
                <span>{item.unloadingPort}</span>
              </TooltipTD>
            </TableCell>
          )
        );

      case headerColumns.FINAL_DESTINATION:
        return (
          enabled && (
            <TableCell key={index} className={"customerName"}>
              <TooltipTD
                label={item.finalDestination}
                className="customized-tooltip"
              >
                <span>{item.finalDestination}</span>
              </TooltipTD>
            </TableCell>
          )
        );

      case headerColumns.BOOKING:
        return (
          enabled && (
            <TableCell key={index} className={"customerName"}>
              <TooltipTD label={item.booking} className="customized-tooltip">
                <span>{item.booking}</span>
              </TooltipTD>
            </TableCell>
          )
        );

      case headerColumns.CONTAINER:
        return (
          enabled && (
            <TableCell key={index} className={"customerName"}>
              <TooltipTD label={item.container} className="customized-tooltip">
                <span>{item.container}</span>
              </TooltipTD>
            </TableCell>
          )
        );

      case headerColumns.CUSTOMER_PO:
        return (
          enabled && (
            <TableCell key={index} className={"customerName"}>
              <TooltipTD label={item.customerPO} className="customized-tooltip">
                <span>{item.customerPO}</span>
              </TooltipTD>
            </TableCell>
          )
        );

      case headerColumns.FREIGHT_FORWARDER:
        return (
          enabled && (
            <TableCell key={index} className={"customerName"}>
              <TooltipTD
                label={item.freightForwarder}
                className="customized-tooltip"
              >
                <span>{item.freightForwarder}</span>
              </TooltipTD>
            </TableCell>
          )
        );

      case headerColumns.TOTAL_VALUE:
        return (
          enabled &&
          isAllowed(generalPermissions.SHIPMENT_AMOUNT) && (
            <TableCell key={column + index} className={"amount"} id="total">
              {isAllowed(generalPermissions.SHIPMENT_AMOUNT)
                ? zeroIsBlank(item.total)
                : notAllowedToSee}
            </TableCell>
          )
        );

      case headerColumns.TOTAL_WEIGHT:
        return (
          enabled && (
            <TableCell key={column + index} className={"amount"} id="weight">
              {zeroIsBlank(item.totalWeight, true)}
            </TableCell>
          )
        );
      case headerColumns.POTENTIAL_LATE_FEE:
        return (
          enabled &&
          isAllowed(generalPermissions.SHIPMENT_AMOUNT) && (
            <TableCell key={column + index} className={"amount"} id="lateFee">
              {isAllowed(generalPermissions.SHIPMENT_AMOUNT)
                ? zeroIsBlank(item.potentialLateFee)
                : notAllowedToSee}
            </TableCell>
          )
        );

      default:
        return <TableCell key={index} />;
    }
  }
  return (
    item && (
      <MainRowStyled
        key={item.id + "MainRowStyled"}
        className={cx(
          mainRowClasses.ShipmentTableRow,
          mainRowClasses.hoverEffect
        )}
      >
        {headers.map((header) =>
          shipmentTableRowSelector({
            column: header.name,
            enabled: header.enabled,
            index: item.id + header.name,
            header,
          })
        )}
        {isThereColumnSetting && <TableCell key={item.id} />}
      </MainRowStyled>
    )
  );
}

export default ShipmentsTableRow;
