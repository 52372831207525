import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import TagsModal from "../../components/Modal/TagsModal";
import {
  getProgressValue,
  getDashboardSearchPath,
  getTagsLabel,
  sortObjectsBy,
} from "../../helpers/helpers";
import { dbTables } from "../../api/types/dbTables";
import { getShipmentBadge } from "../../helpers/shipments";
import {
  GENERAL_PERMISSION_VALUE,
  PERMISSION_TEXT,
  PO_STATUS,
} from "../../helpers/constants";
import { getTagStatus } from "../../helpers/salesOrder";
import { verifyPermission } from "../../hooks/permissions";
import { colors } from "../../assets/jss/variables";
import HiddenDataIconAnimated from "../../helpers/iconAnimation/HiddenDataIconAnimated";
import { ORDER_DASHBOARD_ACTUAL_VIEWS } from "../../components/PurchaseOrderDashboard/sections/helpers";
import { CircularStaticProgress } from "../../components/CircularProgress/CircularStaticProgress";
import { AnimatedListStyled } from "../StyledComponent/AnimatedOpenList";
import ThreePanelButton from "../../components/Buttons/ThreePanelButton";
import { getDiamondStatus } from "../../helpers/ScreenComponetHelper/componetHelper";
import AttentionModal from "../../components/Modal/AttentionModal";

function ShipmentsItem({
  item,
  user,
  onUpdateItem,
  isTradeDashEmployee,
  tags,
  isAllowed,
  salesOrderId,
  salesOrderSetup = {},
  onChangeSalesOrderSetup = () => {},
  shipmentBadgeClasses = {},
  filters,
  type = "SO",
  shipmentDiamonds,
  shipmentProgress,
  iconStyle = {},
  query = "",
  paddingLeft = 0,
}) {
  const [openAttentionModal, setOpenAttentionModal] = useState(false);
  const currentTags = filters.tag || [];
  const setup = salesOrderSetup[item.id] || {};
  const showShipments = setup.shipments || item.shipments.length <= 1;
  let hasTag = false;
  if (!showShipments) {
    item.shipments.forEach((shipments) => {
      const shipmentsTags = shipments.tags || [];
      hasTag = shipmentsTags.some((tag) => currentTags.includes(tag)) || hasTag;
    });
  }

  const getProgress = (shipment) => {
    if (!shipmentProgress) {
      return <div />;
    } else {
      if (shipment.status === PO_STATUS.VOIDED) {
        return <div className="progressContainer" />;
      }

      return (
        <CircularStaticProgress
          label={`${shipment.completedTasks}/${shipment.totalTasks} Tasks`}
          value={+getProgressValue(shipment)}
          thickness={16}
          style={{
            transform: "rotate(270deg)",
            color: "#39F",
          }}
          size={15}
          className="progressContainer"
        />
      );
    }
  };
  let paddingValue = 0;
  if (!shipmentDiamonds) {
    paddingValue = paddingValue + 15;
  }
  if (!shipmentProgress) {
    paddingValue = paddingValue + 15;
  }
  const hiddenStyle = { paddingRight: paddingValue };
  const shipments = item.shipments || [];
  return (
    <>
      {openAttentionModal && (
        <AttentionModal
          isOpen={openAttentionModal}
          title={"Attention"}
          description={PERMISSION_TEXT.CUSTOMER}
          onClick={() => {
            setOpenAttentionModal(false);
          }}
        />
      )}
      <AnimatedListStyled
        className="listContainer"
        style={{
          height: showShipments
            ? shipments.length * 22.1 + (shipments.length - 1) * 5
            : 24,
          paddingLeft,
          textDecoration: "none",
        }}
      >
        <div
          className="displayItems"
          style={{
            opacity: showShipments ? 1 : 0,
            zIndex: showShipments ? 1 : 0,
            pointerEvents: showShipments ? "" : "none",
          }}
          key={item.id + "poListanimated"}
        >
          {shipments
            .sort(sortObjectsBy("number", false))
            .map((shipment, index) => {
              const shipmentPoIds = shipment.purchaseOrderIds || [];
              let poId = shipment.purchaseOrderIds[0];
              if (type === "SO") {
                const intersection = shipmentPoIds.filter((id) =>
                  item.purchaseOrderIds.includes(id)
                );
                poId = intersection[0] || poId;
              }
              const shipmentTags = shipment.tags || [];
              const isAvalible = verifyPermission({
                user,
                permissionToCheck: [
                  shipment.customerId,
                  GENERAL_PERMISSION_VALUE.ALL_CUSTOMERS,
                ],
              });
              const showTag = isAllowed("see_tags") && isAvalible;

              return (
                <div
                  key={shipment.id + index + item.id}
                  style={{
                    ...hiddenStyle,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  className="orderList"
                >
                  <div className="labelText">
                    {shipmentDiamonds &&
                      getDiamondStatus({
                        diamondStatus: shipment.diamondStatus,
                        itemStatus: shipment.status,
                      })}
                    {getProgress(shipment)}
                    {showTag && (
                      <div key={shipment.id + item.id + "tagModalcontainer"}>
                        <TagsModal
                          key={shipment.id + item.id + "tagModal"}
                          label={getTagsLabel(tags, shipmentTags).tagsString}
                          tags={getTagsLabel(tags, shipmentTags).tagsArray}
                          allTags={tags}
                          item={shipment}
                          currentUser={user}
                          tagReference={dbTables.SHIPMENTS}
                          onUpdateItem={onUpdateItem}
                          isThereItemRef={false}
                          isReadOnly={isTradeDashEmployee(user)}
                          status={getTagStatus({
                            tags: shipmentTags,
                            currentTags: currentTags,
                          })}
                        />
                      </div>
                    )}
                    {!showTag && <span style={{ width: 24, minWidth: 24 }} />}
                    <NavLink
                      onClick={(ev) => {
                        if (!isAvalible) {
                          setOpenAttentionModal(true);
                          ev.preventDefault();
                          ev.stopPropagation();
                        }
                      }}
                      to={
                        !isAvalible
                          ? "#"
                          : getDashboardSearchPath({
                              salesOrderId,
                              purchaseOrderId: poId,
                              shipmentId: shipment.id,
                              section: ORDER_DASHBOARD_ACTUAL_VIEWS.SHIPMENT,
                            })
                      }
                      style={{
                        overflow: "visible",
                        marginLeft: -5,
                        textDecoration: "none",
                      }}
                      id="list-view-content-shipment"
                    >
                      {getShipmentBadge({
                        shipment: shipment,
                        styles: {
                          opacity: isAvalible ? "" : 0.55,
                        },
                        classes: shipmentBadgeClasses.medium,
                      })}
                    </NavLink>
                    {isAvalible && (
                      <ThreePanelButton
                        soId={salesOrderId}
                        poId={poId}
                        shipmentId={shipment.id}
                        tab={2}
                      />
                    )}
                  </div>
                </div>
              );
            })}
        </div>
        {shipments.length > 0 && (
          <div
            className="iconAnimated"
            onClick={() => {
              onChangeSalesOrderSetup({
                ...salesOrderSetup,
                [item.id]: {
                  ...salesOrderSetup[item.id],
                  shipments: !salesOrderSetup[item.id]
                    ? true
                    : !salesOrderSetup[item.id].shipments,
                },
              });
            }}
            style={{
              opacity: !showShipments ? 1 : 0,
              zIndex: !showShipments ? 1 : 0,
              ...iconStyle,
              cursor: "pointer",
              pointerEvents: !showShipments ? "" : "none",
            }}
          >
            {HiddenDataIconAnimated({
              styles: {
                display: "flex",
              },
              color: hasTag ? colors.diamondRed : "",
              showHighlight: query
                ? shipments.some((shipment) =>
                    `${shipment.number}`.includes(query)
                  )
                : false,
            })}
            <span
              className="animateText"
              style={{
                color: hasTag ? colors.diamondRed : "",
              }}
              key={item.id + "shipmentsLength"}
            >
              {item.shipments.length}
            </span>
          </div>
        )}
      </AnimatedListStyled>
    </>
  );
}

export default ShipmentsItem;
