import { colors } from "../../assets/jss/variables";
import { Popover, styled } from "@mui/material";
import { TASK_TYPE } from "../../helpers/constants";

export const NavPickerPopoverStyled = styled(Popover)(() => ({
  "&.navPickerPopoverContainer": {
    "& .paper": {
      display: "flex",
      padding: 0,
      alignItems: "flex-start",
      flexDirection: "column",
      maxHeight: "calc(100% - 150px)",
      scrollling: "none",
      gap: 5,
      "& .scrollList": {
        overflow: "scroll",
        "&::-webkit-scrollbar": {
          backgroundColor: "transparent",
          width: 16,
        },
        "&::-webkit-scrollbar-thumb": {
          background: "transparent",
          borderRadius: 7,
        },
        "&::-webkit-scrollbar-track": {
          border: " solid 1px transparent",
        },
        "&:hover": {
          "&::-webkit-scrollbar-thumb": {
            boxShadow: `inset 0 0 10px 10px ${colors.thumbScrollColor}`,
            borderBlock: "1px solid transparent",
            borderInline: "5px solid transparent",
          },
        },
      },
    },
    "& .paperRound": {
      borderRadius: 5,
      border: `1px solid ${colors.whiteBlue}`,
    },
    "& .listContainer": {
      display: "flex",
      padding: "5px 10px 5px 16px",
      cursor: "pointer",
      alignItems: "center",
      width: "100%",
      gap: 16,
      "&:hover": {
        background: "#f5f5f5",
      },
      "& .avatarContainer": {
        zIndex: 2,
      },
      "& .avatarNavLink": {
        cursor: "pointer",
        "&:hover": {
          outline: `${colors.primaryDark} solid 2px !important`,
        },
      },

      "& .itemListNumber": {
        color: colors.primaryDark,
      },
      "& .item-divider": {
        marginInline: "7px 13px",
        width: 13,
        height: "100%",
        marginTop: 6,
        color: colors.primaryDark,
      },
      "& .item-subtitle": {
        color: colors.primaryDark,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: 240,
      },
      "& .no-displaying-avatar": {
        width: 22,
      },
    },
    "& .filterLabel": {
      fontSize: 12,
      color: colors.primaryDark,
      marginBottom: 0,
      display: "flex",
      alignItems: "center",
      gap: 5,
      position: "relative",
      minWidth: 55,
      textTransform: "uppercase",
      fontWeight: 600,
      cursor: "pointer",
    },
    "& .selectedStyle": {
      boxShadow: "4px 0px 0px 0px #218cff inset",
      "& .no-displaying-avatar": {
        width: 22,
      },
    },
    "& .NavPicker": {
      "& .MuiPopover-paper": {
        width: "auto",
        minWidth: 175,
        minHeight: 35,
        background: "#ffffff",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
        borderRadius: 5,
        padding: "5px 0px",
        maxHeight: "calc(100vh - 65px)",
        "& .Mui-selected": {
          backgroundColor: " unset",
        },
      },
    },

    "& .listNavpick": {
      display: "flex",
      padding: "5px 25px 5px 16px",
      cursor: "pointer",
      alignItems: "center",

      "& .itemListNumber": {
        color: colors.primaryDark,
        backgroundColor: "unset !important",
      },
      "& .item-divider": {
        marginInline: "12px 18px",
        width: 13,
        height: "100%",
        marginTop: 6,
        backgroundColor: "unset !important",
        color: colors.primaryDark,
      },
      "& .item-subtitle": {
        color: colors.primaryDark,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: 240,
        backgroundColor: "unset !important",
      },
    },
    "& .textFieldContainer": {
      width: "100%",
      padding: 8,
      borderBottom: `2px solid ${colors.whiteBlue}`,
    },
  },
  "& .salesOrderAutocomplete": {
    gap: 17,
    display: "grid !important",
    gridTemplateColumns: "10px 1fr 10px 170px 10px 170px  10px 170px  ",
    color: colors.primaryDark,
    fontSize: 16,
    "& .divider": {
      width: 10,
      height: 1,
      background: colors.primaryDark,
    },
    "& .toolTipText": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      "& span": {
        width: "fit-content",
        textWrap: "nowrap",
      },
    },
  },
}));

export const MultiUserPickerPopoverStyled = styled(Popover)(() => ({
  "&.multiUserPickerPopoverContainer": {
    "& .selectAllNoneContainer": {
      fontWeight: 400,
      color: "#1C53B7",
      paddingBlock: 10,
      display: "flex",
      justifyContent: "center",
      position: "sticky",
      top: 0,
      background: "white",
      width: "100%",
      zIndex: 5,
      fontSize: "0.80em",
      "& .select-all-button": {
        margin: "0px 2px",
        cursor: "pointer",
        textDecoration: "underline",
      },
      "& .select-none-button": {
        margin: "0px 2px",
        cursor: "pointer",
        textDecoration: "underline",
      },
    },
    "& .multiUserPapper": {
      height: "65%",
      minHeight: 150,
    },
    "& .listContainer": {
      margin: 0,
      padding: 0,
      "& :hover": {
        background: "#f5f5f5",
      },
    },
    "& .listItem": {
      height: 45,
      display: "flex",
      alignItems: "center",
      minWidth: 135,
      paddingInline: "15px 10px",
      gap: 10,
      color: colors.primaryDark,
      cursor: "pointer",
    },
  },
}));

export const TaskPopoverStyle = styled(Popover)(() => ({
  "&.TaskPopoverContainer": {
    borderRadius: 5,
    "& .paperStyle": {
      padding: "10px 0px 10px 10px",
      boxShadow: "0px 10px 50px 3px rgba(1,31,64,0.59)",
    },
    "& .listTaskContainerPopover": {
      width: 550,
      minHeight: 150,
      maxHeight: 500,
      overflow: "scroll",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      "&::-webkit-scrollbar": {
        backgroundColor: "transparent",
        width: 16,
      },
      "&::-webkit-scrollbar-thumb": {
        background: "transparent",
        borderRadius: 7,
      },
      "&::-webkit-scrollbar-track": {
        border: " solid 1px transparent",
      },
      "&:hover": {
        "&::-webkit-scrollbar-thumb": {
          boxShadow: `inset 0 0 10px 10px ${colors.thumbScrollColor}`,
          borderBlock: "1px solid transparent",
          borderInline: "5px solid transparent",
        },
      },
    },

    "& .taskGroup": {
      width: "100%",
      height: "100%",
    },
    "& .badgeRow": {
      height: 45,
      display: "flex",
      alignItems: "center",
    },
    "& .firstTask": {
      borderTop: `1px solid ${colors.dividerColor}`,
    },
    "& .taskRow": {
      display: "grid",
      alignItems: "center",
      gridTemplateColumns: "38px auto 70px min-content",
      height: 40,
      minHeight: 40,
      borderBottom: `1px solid ${colors.dividerColor}`,
      gap: 15,
      marginLeft: 20,
      "& .taskLabelContainer": {
        overflow: "hidden",
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: 2,
        textOverflow: "ellipsis",
        whiteSpace: "unset",
        height: "auto",
        fontSize: 14,
        fontWeight: 500,
        "& .text-static-name": {
          justifyContent: "flex-start",
          display: "unset",
          whiteSpace: "unset",
        },
        "& .labelContainer": {
          display: "flex",
          gap: 5,
          alignItems: "center",
        },
        "& .span-task-description": {
          display: "-webkit-box",
          overflow: "hidden",
          textOverflow: "ellipsis",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: 2,
          wordBreak: "break-word",
        },
      },
    },
    [`& .task${TASK_TYPE.SALES_ORDER}`]: {
      background: colors.salesOrderBackgroundColor,
    },
    [`& .task${TASK_TYPE.PURCHASE_ORDER}`]: {
      background: colors.purchaseOrderActivityBackgroundColor,
    },
    [`& .task${TASK_TYPE.SHIPMENT}`]: {
      background: colors.shipmentActivityBackgroundColor,
    },
    "& .dateTaskBox": {
      display: "flex",
      fontSize: 13,
      alignItems: "center",
      justifyContent: "flex-end",
    },
    "& .userTaskBox": {
      display: "flex",
      alignItems: "center",
      gap: 5,
      justifyContent: "flex-start",
      width: 105,
      fontSize: 13,
      height: "100%",
      paddingInline: 2,
    },
  },
}));

export const OnlyFromStyled = styled("div")(() => ({
  "&.onlyFromContainer": {
    display: "flex",
    alignItems: "center",
    minWidth: 98,
  },
  "& .oneUser": {
    minWidth: 70,
    maxWidth: 110,
    paddingRight: 10,
    paddingLeft: 10,
    display: "flex",
    alignItems: "center",
    gap: 4,
  },
  "& .onlyFromData": {
    minWidth: 70,
    height: 20,
    gap: 6,
    display: "flex",
    alignItems: "center",
    marginRight: 6,
    "& .selected-users-only-from": {
      display: "flex",
    },
  },
  "& .onlyFrom": {
    display: "inline-block",
    color: colors.primaryDark,
    fontWeight: 700,
    fontSize: 12,
    padding: 0,
    lineHeight: "12px",
  },
}));

export const ShipmentPopoverStyled = styled(Popover)(() => ({
  "&.shipmentPopoverContainer": {
    "& .paper": {
      boxShadow: "0px 10px 50px 3px rgba(1,31,64,0.59)",
      background: "#F7F9FC",
      borderRadius: 10,
      "& .shipment-popover-container": {
        maxHeight: "calc(100vh - 210px)",
        overflow: "auto",
        margin: "8px 6px 8px 16px",

        "& .shipment-content": {
          display: "flex",
          alignItems: "center",
          gap: 16,
          margin: "6px 6px 6px 0px",
          "& .shipment-content-final-destination": {
            fontSize: 14,
            fontWeight: 400,
            color: colors.primaryDark,
            width: 150,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        },

        "&::-webkit-scrollbar": {
          backgroundColor: "transparent",
          width: 16,
        },
        "&::-webkit-scrollbar-thumb": {
          background: "transparent",
          borderRadius: 7,
        },
        "&::-webkit-scrollbar-track": {
          border: " solid 1px transparent",
        },
        "&:hover": {
          "&::-webkit-scrollbar-thumb": {
            boxShadow: `inset 0 0 10px 10px ${colors.thumbScrollColor}`,
            borderBlock: "1px solid transparent",
            borderInline: "5px solid transparent",
          },
        },
      },
    },
  },
}));
