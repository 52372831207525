import { styled } from "@mui/material";
import AppConfig from "../../constants/AppConfig";

export const shipmentBadgeClass = {
  primary: "primary",
  secondary: "secondary",
  mediumInverted: "mediumInverted",
  medium: "medium",
};

export const ShipmentBadge = styled("div")(() => ({
  "&.badgeStyle": {
    width: 39,
    height: 15,
    background: AppConfig.themeColors.shipmentColor,
    color: "#25486D",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 12,
    fontSize: 9,
    margin: 0,
    cursor: "pointer",
    border: `2px solid ${AppConfig.themeColors.shipmentBordeColor}`,
    "&:hover": {
      boxShadow: "0px 0px 1px #000",
    },
  },
  [`&.${shipmentBadgeClass.primary}`]: {
    "& p": {
      margin: 0,
      fontSize: "9px !Important",
      height: 9,
      lineHeight: 1,
    },
    "&:hover": {
      background: "#F4B9FF",
      textShadow: "0px 0px 1px #000",
    },
  },
  [`&.${shipmentBadgeClass.secondary}`]: {
    height: 19,
    width: 47,
    background: "#fff",
    "& p": {
      margin: 0,
      fontSize: "11px !Important",
      height: 11,
      lineHeight: 1,
    },
    "&:hover": {
      textShadow: "0px 0px 1px #000",
    },
  },
  [`&.${shipmentBadgeClass.mediumInverted}`]: {
    background: "#fff",
    width: 48,
    height: 20,
    fontSize: 12,
    "& p": {
      margin: 0,
      fontSize: 12,
      height: 12,
      lineHeight: 1,
    },
    "&:hover": {
      textShadow: "0px 0px 1px #000",
    },
  },
  [`&.${shipmentBadgeClass.medium}`]: {
    width: 48,
    height: 20,
    fontWeight: 400,
    fontSize: 12,
    "& p": {
      margin: 0,
      fontSize: 12,
      height: 12,
      lineHeight: 1,
    },
    "&:hover": {
      textShadow: "0px 0px 1px #000",
    },
  },
}));
