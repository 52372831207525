import React, { useState, useEffect, useRef, useCallback } from "react";
import { FilterTaskContainer, TaskTableContainer } from "./styles";
import GeneralFilters from "../../components/FilterTable/GeneralFilters";
import ButtonFilter from "../../components/PurchaseOrderList/ButtonFilter";
import { useCompanyId, useJSONLocalStorage } from "../../hooks";
import {
  getTheDate,
  useTaskNotificationCreator,
  sortObjectsBy,
  getRandomId,
  taskNotificationType,
  debounce,
  getMaxDate,
  getScreenListGrid,
} from "../../helpers/helpers";
import { useIsAllowedFunction } from "../../hooks/permissions";
import {
  dbTables,
  typeOfTask,
  USER_CUSTOM_SETTINGS,
} from "../../api/types/dbTables";
import FilterDisplay from "../../components/PurchaseOrderList/FilterDisplay";
import moment from "moment";
import SearchBox from "../../components/SearchBox/SearchBox";
import { tasksHeaderCells } from "../../components/Managers/TableHeaderProvider";
import {
  getScreenTraceName,
  getToolTipArrayValue,
} from "../../helpers/purchaseOrderList";
import AttentionModal from "../../components/Modal/AttentionModal";
// import { taskData } from "../../helpers/TaskData";
import MainTableRefactor from "../../components/filterTableRefactor/MainTableRefactor";
import BasicPagination from "../../components/Tables/BasicPagination";
import {
  excludeElementsScreen,
  LIST_VIEW_BUTTONS_GRID,
  taskStatus,
  TYPE_OF_TAGS,
} from "../../helpers/constants";
import {
  filterTaskList,
  triggerTaskTypes,
  updateColumnHeaders,
} from "../../helpers/tasks";
import { columnHeaderSetup } from "../../helpers/screenHeaderSetup";
import {
  useUser,
  useCompanyUsers,
  useUserCustomSetting,
} from "../../hooks/user";
import { useFactories } from "../../hooks/factories";
import { useCustomers } from "../../hooks/customers";
import { useTags } from "../../hooks/tags";
import MultipleSelect from "../../components/MultipleSelect/MultipleSelect";
import { getFilteredTaskStatus } from "../../helpers/screens";
import {
  getCorrectTimezone,
  taskCompletionVerifier,
} from "../../helpers/ganttChart";
import { firestore, performanceFirebase } from "../../firebase";
import { trace } from "firebase/performance";
import { getFunctions, httpsCallableFromURL } from "firebase/functions";
import { doc, updateDoc } from "firebase/firestore";
import {
  getFunctionByName,
  globalEnvironment,
} from "../../constants/globalVariables";
import Mark from "mark.js";
import TaskTableRow from "./TaskTableRow";
import { useMediaQuery } from "@mui/material";
import { ButtonReset } from "../StyledComponent/ButtonReset";
import { cx } from "@emotion/css";
import LoadingBackdrop from "../../components/WholeScreenFocusBackdrop/LoadingBackdrop";
import { isEqual } from "lodash";
import DiamondLegend from "../../components/LegendStatus/DiamondLegend";
import DateRangePicker from "../../components/DatePicker/DateRangePicker";
import TaskStatusFilters from "../../components/ListViews/TaskStatusFilters";
import { SalesOrderIcon } from "../../helpers/iconSvgPath";
import ColumnHeader from "../../api/model/ColumnHeader.model";

const dateFields = ["Start Date", "Due Date"];

const BODY_ID = "taskListTableRow";

function TaskList({ onGroupBySalesOrder = () => {} }) {
  const dateRef = useRef(null);
  const { get, set } = useJSONLocalStorage("taskScreenFilters");
  const dataLocalStorage = get() || {};
  const user = useUser();
  const userCustomSettings = useUserCustomSetting({
    userId: user.id,
    setting: USER_CUSTOM_SETTINGS.SCREEN_HEADERS,
  });
  const companyId = useCompanyId();
  const tags = useTags();
  const [tasks, setTasks] = useState([]);
  const [filteredTasks, setFilteredTasks] = useState([]);

  const isAllowed = useIsAllowedFunction();
  const [filters, setFilters] = useState({
    assignedTo: dataLocalStorage.assignedTo || [],
    customer: dataLocalStorage.customer || [],
    factory: dataLocalStorage.factory || [],
    tag: dataLocalStorage.tag || [],
    completed: dataLocalStorage.completed || false,
    inProgress: dataLocalStorage.inProgress || false,
    late: dataLocalStorage.late || false,
    nearDue: dataLocalStorage.nearDue || false,
    notStarted: dataLocalStorage.notStarted || false,
    date: dataLocalStorage.date || false,
    dateRange: dataLocalStorage.dateRange || "",
  });

  const [nonLookUpfilters, setNonLookUpFilters] = useState({
    query: dataLocalStorage.query || "",
    sortedColumn: dataLocalStorage.sortedColumn || "number",
    orderBy: dataLocalStorage.orderBy || "ASC",
  });
  const [loading, setLoading] = useState(false);
  const customers = useCustomers();
  const factories = useFactories();
  const companyUsers = useCompanyUsers({ id: companyId });
  const currentUser = useUser();
  const [filterDate, setFilterDate] = useState(
    dataLocalStorage.date || "Due Date"
  );
  const [openList, setOpenList] = useState(false);
  const [callFunction, setCallFunction] = useState(false);
  const [previousFilter, setPreviousFilter] = useState({});
  const [existsDate, setExistsDate] = useState(dataLocalStorage.date || false);
  const [openDate, setOpenDate] = useState(false);
  const createTaskNotification = useTaskNotificationCreator();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(
    dataLocalStorage.rowsPerPage || 10
  );
  const [openAttentionModal, setOpenAttentionModal] = useState(false);
  const [descriptionAttentionModal, setDescriptionAttentionModal] =
    useState("");
  const [lastFilter, setLastFilter] = useState("");
  const [headerCells, setHeaderCells] = useState([]);

  const onDebounceCall = useCallback(
    debounce(() => {
      setCallFunction(true);
    }, 1250),
    []
  );

  const onDebounceMark = useCallback(
    debounce(() => {
      performMark(nonLookUpfilters.query);
    }, 300),
    [nonLookUpfilters.query]
  );

  function changeFilters(newFilters) {
    if (!isEqual(filters, newFilters)) {
      onDebounceCall();
      setFilters({ ...newFilters });
      setPage(1);
    }
  }

  useEffect(() => {
    if (
      !openList &&
      !isEqual(filters, previousFilter) &&
      Object.keys(userCustomSettings).length !== 0
    ) {
      onDebounceCall();
    }
  }, [openList, Object.keys(userCustomSettings).length]);

  useEffect(() => {
    if (!callFunction) {
      return;
    }
    setCallFunction(false);
    if (openList) {
      return;
    }
    if (
      filters.date &&
      filters.date !== "" &&
      !filters.dateRange.start &&
      !filters.dateRange.end
    ) {
      return;
    }
    if (isEqual(filters, previousFilter)) {
      return;
    }
    setTasks([]);
    setFilteredTasks([]);
    setLoading(true);
    set({ ...nonLookUpfilters, ...filters, rowsPerPage });
    getTasks();
    setPage(1);
  }, [callFunction]);

  useEffect(() => {
    setPage(1);
    set({ ...filters, ...nonLookUpfilters, rowsPerPage });
    if (!tasks.length) return;
    const filteredList = filterTaskList({
      list: tasks,
      headerCells,
      nonLookUpfilters,
    });

    setFilteredTasks(filteredList);
  }, [nonLookUpfilters]);

  useEffect(() => {
    set({ ...filters, ...nonLookUpfilters, rowsPerPage });
  }, [rowsPerPage]);

  useEffect(() => {
    function onAdjustHeader() {
      let userHeaderCell = userCustomSettings.taskHeaderSetup || [];
      userHeaderCell = userHeaderCell.map((header) => {
        return {
          ...header,
          ...tasksHeaderCells[header.name],
          styles: {
            ...tasksHeaderCells[header.name].styles,
            width: header.width || 0,
          },
        };
      });
      let headerCellsCpy = [...userHeaderCell];
      if (!isEqual(headerCells, headerCellsCpy)) {
        setHeaderCells(headerCellsCpy);
      }
    }
    onAdjustHeader();
  }, [rowsPerPage, page, nonLookUpfilters, tasks]);

  function changeButtonFilter(filter) {
    changeFilters({ ...filters, [filter]: !filters[filter] });
  }

  async function getTasks() {
    setPreviousFilter(filters);
    const traceName = getScreenTraceName({
      filters,
      scope: dbTables.PURCHASE_ORDER_TASKS,
    });
    const traceInstance = trace(performanceFirebase, traceName);
    console.log(traceInstance);
    if (traceName) {
      traceInstance.start();
    }
    setLoading(true);
    const { status } = getFilteredTaskStatus({ filters });
    const filtersToSend = {
      ...filters,
      dateStart: filters.dateRange
        ? moment(filters.dateRange.start).valueOf()
        : "",
      dateEnd: filters.dateRange
        ? moment(filters.dateRange.end).endOf("day").valueOf()
        : "",
      status,
    };
    delete filtersToSend.dateRange;
    try {
      const functions = getFunctions();
      const tasksEndpoint = httpsCallableFromURL(
        functions,
        getFunctionByName({
          name: "listview",
          env: globalEnvironment,
          params: "/tasks",
        })
      );
      tasksEndpoint({
        companyId: companyId,
        userId: user.id,
        filters: filtersToSend,
      }).then((result) => {
        const { tasksDB } = result.data;
        setTasks([...tasksDB]);
        const filteredList = filterTaskList({
          list: tasksDB,
          headerCells,
          nonLookUpfilters,
        });
        setFilteredTasks(filteredList);
        if (traceName) {
          traceInstance.stop();
        }
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      if (traceName) {
        traceInstance.stop();
      }
      setLoading(false);
    }
  }

  const markInstance = new Mark(document.getElementById(BODY_ID));

  function performMark(keyword = "") {
    var options = {
      separateWordSearch: false,
      diacritics: false,
      debug: false,
      acrossElements: true,
      accuracy: "partially",
      exclude: excludeElementsScreen,
    };
    markInstance.unmark({
      done: () => {
        setTimeout(() => markInstance.mark(keyword, options), 150);
      },
    });
  }

  useEffect(() => {
    if (!loading) {
      onDebounceMark();
    }
  }, [
    nonLookUpfilters,
    loading,
    rowsPerPage,
    page,
    nonLookUpfilters.groupBySalesOrder,
  ]);

  useEffect(() => {
    const filteredList = filterTaskList({
      list: tasks,
      headerCells,
      nonLookUpfilters,
    });
    setFilteredTasks(filteredList);
  }, [headerCells]);

  useEffect(() => {
    if (window.location.href.includes("redirectFromEmail")) {
      const assignedTo = [];
      assignedTo.push(user.id);
      setFilters({
        factory: [],
        customer: [],
        assignedTo,
        late: true,
        favorite: false,
        nearDue: true,
        inProgress: false,
        complete: false,
      });
      setNonLookUpFilters({
        query: "",
      });
      getTasks();
    }
  }, []);

  function toggleTask(task) {
    const dayOffset = moment()
      .startOf("day")
      .diff(moment(task.finishDate).startOf("day"), "days");
    const newArray = [...tasks];
    const { duration, startDate, finishDate } = taskCompletionVerifier({
      task: {
        ...task,
        startDate: getCorrectTimezone({
          date: task.startDate,
          isServerTime: true,
        }).valueOf(),
        finishDate: getCorrectTimezone({
          date: task.finishDate,
          isServerTime: true,
        }).valueOf(),
      },
    });
    const taskIndex = newArray.findIndex(
      (item) =>
        task.id === item.id &&
        task.salesOrderId === item.salesOrderId &&
        task.purchaseOrderId === item.purchaseOrderId
    );
    if (!task.complete) {
      const notificationId = getRandomId();
      if (dayOffset !== 0) {
        const currentShipment = task.shipment || {};
        createTaskNotification({
          task: {
            ...task,
            completedBy: currentUser.id,
            PONumber: task.PONumber,
            SONumber: task.SONumber,
            shipmentNumber: currentShipment.number,
            SOT: !task.factoryId ? true : false,
            duration,
          },
          randomId: notificationId,
          type: taskNotificationType.COMPLETED,
        });
      }
      newArray[taskIndex] = {
        ...newArray[taskIndex],
        complete: !task.complete,
        completedBy: user.id,
        status: taskStatus.COMPLETE,
        completing: false,
      };
      setTasks(newArray);
      const filteredList = filterTaskList({
        list: newArray,
        headerCells,
        nonLookUpfilters,
      });

      setFilteredTasks(filteredList);

      const fieldsToUpdate = {
        complete: !task.complete,
        completedBy: currentUser.id,
        status: taskStatus.COMPLETE,
        dayOffset,
        moved: true,
        triggerType: triggerTaskTypes.COMPLETE,
        duration,
        startDate,
        finishDate,
      };
      if (task.type === typeOfTask.SHIPMENT) {
        updateDoc(
          doc(
            firestore,
            `${dbTables.COMPANIES}/${companyId}/${dbTables.SHIPMENTS}/${task.shipmentId}/${dbTables.SHIPMENT_TASKS}/${task.id}`
          ),
          {
            ...fieldsToUpdate,
          }
        );
      } else if (task.type === typeOfTask.SALES_ORDER) {
        updateDoc(
          doc(
            firestore,
            `${dbTables.COMPANIES}/${companyId}/${dbTables.SALES_ORDERS}/${task.salesOrderId}/${dbTables.SALES_ORDER_TASKS}/${task.id}`
          ),
          {
            ...fieldsToUpdate,
          }
        );
      } else {
        updateDoc(
          doc(
            firestore,
            `${dbTables.COMPANIES}/${companyId}/${dbTables.PURCHASE_ORDERS}/${task.purchaseOrderId}/${dbTables.PURCHASE_ORDER_TASKS}/${task.id}`
          ),
          {
            ...fieldsToUpdate,
          }
        );
      }
    }
  }

  function onOpenDatePicker(field) {
    setOpenList(true);
    setExistsDate(true);
    setFilterDate(field);
    setFilters({
      ...filters,
      date: field,
      dateRange: { start: "", end: "" },
    });
    setTimeout(() => setOpenDate(true), 50);
  }

  function resetFilters() {
    setFilters({
      assignedTo: [user.id],
      factory: [],
      customer: [],
      dateRange: "",
      date: "",
      late: true,
      nearDue: false,
      inProgress: false,
      notStarted: false,
      completed: false,
    });

    setNonLookUpFilters({
      ...nonLookUpfilters,
      query: "",
      groupBySalesOrder: false,
    });
    setFilterDate("");
    setExistsDate(false);
    setPage(1);
    onDebounceCall();
  }

  function changeDate(date) {
    setFilters({
      ...filters,
      dateRange: date,
      date: filterDate,
    });
    setOpenDate(false);
    setOpenList(false);
  }

  function handleCloseDatePicker() {
    setOpenDate(false);
    setOpenList(false);
    if (!filterDate) {
      setFilters({ ...filters, date: "" });
    }
  }

  const matches = useMediaQuery("(max-width:1425px)");

  function handleChangePage(newPage) {
    setPage(newPage);
  }

  function handleOpenAttentionModal(value) {
    if (value === "adding-button-filters-complete") {
      setDescriptionAttentionModal(
        <React.Fragment>
          Are you sure that you want to load ALL the completed tasks?
          <br />
          There could be many thousands and take a long time. It is a good idea
          to select a date range first.
        </React.Fragment>
      );
      setLastFilter("filters");
    } else {
      setDescriptionAttentionModal(
        <React.Fragment>
          Are you sure that you want to remove the date filter? <br /> This will
          load ALL the completed tasks and there could be many thousands and
          take a long time. It is a good idea first remove the completed task
          filter.
        </React.Fragment>
      );
      setLastFilter("filters");
    }
    setOpenAttentionModal(true);
  }

  const updateTask = (task, item, key) => {
    const taskKeyId = task[`${key}Id`] || task[key]?.id;
    if (taskKeyId === item[`${key}Id`]) {
      return { ...task, [key]: item };
    }
    return task;
  };

  function handleUpdateItem(item, tagReference) {
    let tasksCpy = [...tasks];
    tasksCpy = tasksCpy.map((task) => {
      if (tagReference === dbTables.SALES_ORDERS) {
        return updateTask(task, item, "salesOrder");
      } else if (
        tagReference === dbTables.PURCHASE_ORDERS &&
        task.type === typeOfTask.PURCHASE_ORDER
      ) {
        return updateTask(task, item, "purchaseOrder");
      } else if (tagReference === dbTables.SHIPMENTS) {
        return updateTask(task, item, "shipment");
      }
      return task;
    });

    setTasks([...tasksCpy]);
    const filteredList = filterTaskList({
      list: tasksCpy,
      headerCells,
      nonLookUpfilters,
    });
    setFilteredTasks(filteredList);
  }

  const clearFilterData = ({ filterKey }) => {
    setFilters({ ...filters, [filterKey]: [] });
    onDebounceCall();
  };

  const filterCustomerActive = filters.customer?.length > 0;
  const filterFactoryActive = filters.factory?.length > 0;
  const filterTagActive = isAllowed("see_tags") && filters.tag?.length > 0;
  const filterAssignedTo = filters.assignedTo?.length > 0;

  return (
    <React.Fragment>
      {openAttentionModal && (
        <AttentionModal
          isOpen={openAttentionModal}
          description={descriptionAttentionModal}
          title="Attention"
          cancellable={true}
          onClose={() => {
            setOpenAttentionModal(false);
            setLastFilter("");
            setDescriptionAttentionModal(<React.Fragment></React.Fragment>);
            setOpenList(false);
          }}
          onClick={() => {
            setOpenList(false);
            if (lastFilter === "filters") {
              changeFilters({
                ...filters,
                completed: !filters["completed"],
              });
            }
            setOpenAttentionModal(false);
            setDescriptionAttentionModal(<React.Fragment></React.Fragment>);
            setLastFilter("");
          }}
          confirmationText="Proceed"
          cancelText="Cancel"
          acceptBlue={false}
        />
      )}
      {loading && (
        <LoadingBackdrop
          size={60}
          backdropStyle={{
            position: "fixed",
          }}
          withLogo={true}
        />
      )}

      <FilterTaskContainer className="root">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <GeneralFilters
            onChange={changeFilters}
            handleListOpen={(value) => {
              setTimeout(() => setOpenList(value), value ? 200 : 50);
            }}
            currentFilters={filters}
            enabledFields={{
              customer: true,
              assignedTo: true,
              factory: true,
              date: true,
              tag: true,
            }}
            enableExport={false}
            onOpenDatePicker={onOpenDatePicker}
            dateFields={dateFields}
            tagFilters={[TYPE_OF_TAGS.MISMATCH_TAG, TYPE_OF_TAGS.FILE_TAG]}
          />
          <SearchBox
            placeholder="Search for PO or Sales Order or shipment number, Task, etc."
            onDebounceValue={(nonLookUpfilters, value) => {
              setNonLookUpFilters({
                ...nonLookUpfilters,
                query: value,
              });
            }}
            value={nonLookUpfilters.query}
            filters={nonLookUpfilters}
            hasBeenReseted={(nonLookUpfilters, value) => {
              setNonLookUpFilters({
                ...nonLookUpfilters,
                query: value,
              });
            }}
          />
        </div>
        <div className="contentContainer">
          <div className="contentOptions">
            <DiamondLegend />
            <div
              className="buttonsFilter"
              style={{
                gridTemplateColumns: getScreenListGrid({
                  grid: LIST_VIEW_BUTTONS_GRID.TASK_LIST,
                  filtersActive: [
                    filterCustomerActive,
                    filterFactoryActive,
                    filterTagActive,
                    filterAssignedTo,
                  ],
                }),
              }}
            >
              <TaskStatusFilters
                filters={filters}
                changeButtonFilter={changeButtonFilter}
                existsDate={existsDate}
                handleOpenAttentionModal={() => {
                  setOpenList(true);
                  handleOpenAttentionModal("adding-button-filters-complete");
                }}
                changeFilters={changeFilters}
              />

              <ButtonFilter
                buttonId="list-view-button-filter-groupbypo"
                value={nonLookUpfilters.groupBySalesOrder}
                onClick={() =>
                  onGroupBySalesOrder({
                    groupBySalesOrder: !nonLookUpfilters.groupBySalesOrder,
                  })
                }
                style={{ width: "auto" }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <SalesOrderIcon style={{ marginRight: matches ? 0 : 6 }} />
                  {!matches && "Group By Sales Order"}
                </div>
              </ButtonFilter>

              {filterAssignedTo && (
                <FilterDisplay
                  clearIconId="list-view-filter-badge-clear-assignedto"
                  onClear={() => clearFilterData({ filterKey: "assignedTo" })}
                  label="Assigned To"
                  tooltip={
                    getToolTipArrayValue(
                      filters.assignedTo,
                      companyUsers,
                      "displayName"
                    ).tooltip
                  }
                  value={
                    getToolTipArrayValue(
                      filters.assignedTo,
                      companyUsers,
                      "displayName"
                    ).value
                  }
                  filters={filters}
                />
              )}
              {filterCustomerActive && (
                <FilterDisplay
                  clearIconId="list-view-filter-badge-clear-customer"
                  onClear={() => clearFilterData({ filterKey: "customer" })}
                  label={
                    filters.customer.length === 1 ? "Customer" : "Customers"
                  }
                  tooltip={
                    getToolTipArrayValue(filters.customer, customers).tooltip
                  }
                  value={
                    getToolTipArrayValue(filters.customer, customers).value
                  }
                  filters={filters}
                />
              )}
              {filterFactoryActive && (
                <FilterDisplay
                  clearIconId="list-view-filter-badge-clear-factory"
                  onClear={() => clearFilterData({ filterKey: "factory" })}
                  label={filters.factory.length === 1 ? "Vendor" : "Vendors"}
                  tooltip={
                    getToolTipArrayValue(filters.factory, factories).tooltip
                  }
                  value={getToolTipArrayValue(filters.factory, factories).value}
                  filters={filters}
                />
              )}

              {filterTagActive && (
                <FilterDisplay
                  clearIconId="list-view-filter-badge-clear-label"
                  onClear={() => clearFilterData({ filterKey: "tag" })}
                  label={filters.tag.length === 1 ? "Label" : "Labels"}
                  tooltip={getToolTipArrayValue(filters.tag, tags).tooltip}
                  value={tags && getToolTipArrayValue(filters.tag, tags).value}
                  filters={filters}
                />
              )}

              {existsDate && (
                <>
                  <div
                    onClick={() => {
                      setOpenDate(true);
                      setOpenList(true);
                    }}
                    ref={dateRef}
                  >
                    <FilterDisplay
                      clearIconId="list-view-filter-badge-clear-date"
                      onClear={() => {
                        setFilterDate("");
                        setExistsDate(false);
                        setFilters({
                          ...filters,
                          date: "",
                          dateRange: "",
                        });
                        setOpenDate(false);
                        setOpenList(false);
                        onDebounceCall();
                      }}
                      label={filters.date}
                      filters={filters}
                      value={
                        filters.dateRange
                          ? `${getTheDate(
                              filters.dateRange.start
                            )} - ${getTheDate(filters.dateRange.end)}`
                          : "Pick a date"
                      }
                    />
                  </div>
                  {openDate && (
                    <DateRangePicker
                      open={openDate}
                      el={dateRef.current}
                      onClose={handleCloseDatePicker}
                      onChange={changeDate}
                      value={filters.dateRange}
                      cancelIcon
                      maxDate={getMaxDate(filters.date)}
                    />
                  )}
                </>
              )}
              <ButtonReset
                id="list-view-button-filter-reset"
                className={cx("root", "taskReset")}
                onClick={resetFilters}
              >
                {matches ? "RESET" : "RESET FILTERS"}
              </ButtonReset>
            </div>
          </div>
          <div className="contentSettings">
            {!nonLookUpfilters.groupBySalesOrder && (
              <MultipleSelect
                items={headerCells.sort(sortObjectsBy("index", false))}
                onChange={setHeaderCells}
                type={columnHeaderSetup.TASK_HEADER_SETUP}
                onClose={() => {
                  let newItemsCpy = [...headerCells];
                  newItemsCpy = newItemsCpy.map((item) => ({
                    enabled: item.enabled,
                    index: item.index,
                    name: item.name,
                    unmovable: item.unmovable || false,
                    width: item.styles.width,
                  }));
                  updateDoc(
                    doc(
                      firestore,
                      `${dbTables.USERS}/${user.id}/${dbTables.CUSTOM_SETTINGS}/${USER_CUSTOM_SETTINGS.SCREEN_HEADERS}`
                    ),
                    {
                      taskHeaderSetup: newItemsCpy,
                    }
                  );
                }}
              />
            )}
          </div>
          <TaskTableContainer
            className={cx("contentListTask", {
              groupContainer: nonLookUpfilters.groupBySalesOrder,
            })}
            id={BODY_ID}
          >
            <MainTableRefactor
              headCells={headerCells.sort(sortObjectsBy("index", false))}
              bodyRows={filteredTasks.slice(
                (page - 1) * rowsPerPage,
                page * rowsPerPage
              )}
              renderRow={(item, index) => {
                return (
                  <TaskTableRow
                    key={item.id + index}
                    task={item}
                    index={index}
                    onComplete={toggleTask}
                    companyUsers={companyUsers}
                    filters={filters}
                    tags={tags}
                    onUpdateItem={handleUpdateItem}
                    user={currentUser}
                    isAllowed={isAllowed}
                    headerCells={headerCells.sort(
                      sortObjectsBy("index", false)
                    )}
                  />
                );
              }}
              filters={nonLookUpfilters}
              onUpdateFilters={(nonLookUpfilters) => {
                setPage(1);
                setNonLookUpFilters({ ...nonLookUpfilters });
              }}
              onResizeColumn={async ({ cell, offset }) => {
                setLoading(true);
                let newHeaderCells = [...headerCells];
                newHeaderCells = newHeaderCells.map((headerCell) => {
                  if (headerCell.name === cell.name) {
                    return {
                      ...headerCell,
                      styles: {
                        ...headerCell.styles,
                        width: offset,
                      },
                    };
                  }
                  return headerCell;
                });
                let newItemsCpy = [...newHeaderCells];
                newItemsCpy = newItemsCpy.map((item) => ({
                  ...new ColumnHeader({
                    enabled: item.enabled,
                    index: item.index,
                    name: item.name,
                    unmovable: item.unmovable || false,
                    width: item.styles.width,
                  }),
                }));
                setHeaderCells(newHeaderCells);
                const successfullySaved = await updateColumnHeaders({
                  header: columnHeaderSetup.TASK_HEADER_SETUP,
                  columnHeaders: newItemsCpy,
                  user,
                });
                if (successfullySaved) {
                  setLoading(false);
                }
              }}
            />
          </TaskTableContainer>
        </div>
        {!nonLookUpfilters.groupBySalesOrder && (
          <BasicPagination
            paginationClass="taskPagination"
            rowsPerPage={rowsPerPage}
            page={page}
            count={getCountPagination(filteredTasks, rowsPerPage)}
            onChangePagination={(event, newPage) => {
              handleChangePage(newPage);
            }}
            onChangeRowsPerPage={(event) => {
              setRowsPerPage(+event.target.value);
              setPage(1);
            }}
            style={{}}
          />
        )}
      </FilterTaskContainer>
    </React.Fragment>
  );

  function getCountPagination(filteredTasks, rowsPerPage) {
    return Math.ceil(filteredTasks.length / rowsPerPage);
  }
}

export default TaskList;
