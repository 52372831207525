import { headerColumns } from "./constants";

export const POHeaderSetup = [
  {
    index: 0,
    name: headerColumns.FAVORITE,
    enabled: true,
    unmovable: true,
    width: 40,
  },
  {
    index: 1,
    name: headerColumns.DIAMOND,
    enabled: true,
    unmovable: true,
    width: 50,
  },
  {
    index: 2,
    name: headerColumns.PROGRESS,
    enabled: true,
    unmovable: true,
    width: 35,
  },
  {
    index: 3,
    name: headerColumns.MENTIONS,
    unmovable: true,
    enabled: true,
    width: 65,
  },
  {
    index: 4,
    name: headerColumns.PO,
    enabled: true,
    unmovable: true,
    width: 180,
  },
  {
    index: 5,
    name: headerColumns.SO,
    enabled: true,
    width: 205,
  },
  {
    index: 6,
    name: headerColumns.SHIPMENTS,
    enabled: true,
    width: 135,
  },
  {
    index: 7,
    name: headerColumns.CUSTOMER,
    enabled: true,
    width: 180,
  },
  {
    index: 8,
    name: headerColumns.CREATION_DATE,
    enabled: true,
    width: 120,
  },
  {
    index: 9,
    name: headerColumns.ORDER_READY_DATE,
    enabled: true,
    width: 130,
  },
  {
    index: 10,
    name: headerColumns.VENDOR,
    enabled: true,
    width: 180,
  },
  {
    index: 11,
    name: headerColumns.DISCOUNT_PERCENTAGE,
    enabled: true,
    width: 120,
  },
  {
    index: 12,
    name: headerColumns.TOTAL,
    enabled: true,
    width: 150,
  },
  {
    index: 13,
    name: headerColumns.POTENTIAL_LATE_FEE,
    enabled: true,
    width: 150,
  },
  {
    index: 14,
    name: headerColumns.CBM,
    enabled: true,
    width: 110,
  },
  {
    index: 15,
    name: headerColumns.SHIPPING_TERMS,
    enabled: true,
    width: 130,
  },
  {
    index: 16,
    name: headerColumns.TOTAL_SHIPMENTS,
    enabled: true,
    width: 100,
  },
  {
    index: 17,
    name: headerColumns.VENDOR_INVOICE,
    enabled: true,
    width: 130,
  },
  {
    index: 18,
    name: headerColumns.UNIQUE_ITEMS,
    enabled: true,
    width: 90,
  },
  {
    index: 19,
    name: headerColumns.DISCOUNT,
    enabled: true,
    width: 150,
  },
  {
    index: 20,
    name: headerColumns.SUB_TOTAL,
    enabled: true,
    width: 150,
  },
  {
    index: 21,
    name: headerColumns.DEPOSIT,
    enabled: true,
    width: 150,
  },
  {
    index: 22,
    name: headerColumns.BALANCE,
    enabled: true,
    width: 150,
  },
  {
    index: 23,
    name: headerColumns.TOTAL_WEIGHT,
    enabled: true,
    width: 120,
  },
  {
    index: 24,
    name: headerColumns.DOCUMENT_DATE,
    enabled: true,
    width: 125,
  },
  {
    index: 25,
    name: headerColumns.LAST_BLANK_COLUMN,
    enabled: true,
    width: 16,
  },
  {
    index: 26,
    name: headerColumns.EXTRA,
    enabled: true,
    width: "100%",
  },
];

export const SOHeaderSetup = [
  {
    index: 0,
    name: "favorite",
    enabled: true,
    unmovable: true,
    width: 40,
  },
  {
    index: 1,
    name: "diamond",
    enabled: true,
    unmovable: true,
    width: 50,
  },
  {
    index: 2,
    name: "progress",
    enabled: true,
    unmovable: true,
    width: 35,
  },
  {
    index: 3,
    name: "mentions",
    unmovable: true,
    enabled: true,
    width: 65,
  },
  {
    index: 4,
    name: "SO",
    enabled: true,
    unmovable: true,
    width: 205,
  },
  {
    index: 5,
    name: "POs",
    enabled: true,
    width: 270,
  },
  {
    index: 6,
    name: "Shipments",
    enabled: true,
    width: 180,
  },
  {
    index: 7,
    name: "Customer",
    enabled: true,
    width: 180,
  },
  {
    index: 8,
    name: headerColumns.CREATION_DATE,
    enabled: true,
    width: 123,
  },
  {
    index: 9,
    name: "Ship by Date",
    enabled: true,
    width: 120,
  },
  {
    index: 10,
    name: headerColumns.REQUIRED_DELIVERY,
    enabled: true,
    width: 110,
  },
  {
    index: 11,
    name: headerColumns.PAY_TERMS_START,
    enabled: true,
    width: 130,
  },
  {
    index: 12,
    name: headerColumns.DISCOUNT_PERCENTAGE,
    enabled: true,
    width: 125,
  },
  {
    index: 13,
    name: headerColumns.TOTAL,
    enabled: true,
    width: 135,
  },
  {
    index: 14,
    name: "Potential Late Penalty",
    enabled: true,
    width: 135,
  },
  {
    index: 15,
    name: "CBM",
    enabled: true,
    width: 110,
  },
  {
    index: 16,
    name: "Total Shipments",
    enabled: true,
    width: 105,
  },
  {
    index: 17,
    name: headerColumns.SALES_INVOICE,
    enabled: true,
    width: 120,
  },
  {
    index: 18,
    name: "Customer PO",
    enabled: true,
    width: 150,
  },
  {
    index: 19,
    name: "Sales Rep.",
    enabled: true,
    width: 150,
  },
  {
    index: 20,
    name: "Unique Items",
    enabled: true,
    width: 95,
  },
  {
    index: 21,
    name: headerColumns.DISCOUNT,
    enabled: true,
    width: 150,
  },
  {
    index: 22,
    name: headerColumns.SUB_TOTAL,
    enabled: true,
    width: 130,
  },
  {
    index: 23,
    name: "Deposit",
    enabled: true,
    width: 150,
  },
  {
    index: 24,
    name: "Balance",
    enabled: true,
    width: 150,
  },
  {
    index: 25,
    name: "Document Date",
    enabled: true,
    width: 120,
  },
  {
    index: 26,
    name: headerColumns.REFERENCE_NUMBER,
    enabled: true,
    width: 115,
  },
  {
    index: 27,
    name: headerColumns.LAST_BLANK_COLUMN,
    enabled: true,
    width: 16,
  },
  {
    index: 28,
    name: "Extra",
    enabled: true,
    width: "100%",
  },
];

export const taskHeaderSetup = [
  {
    index: 0,
    name: "diamond",
    enabled: true,
    unmovable: true,
    width: 35,
  },
  {
    index: 1,
    name: headerColumns.TYPE,
    enabled: true,
    width: 205,
  },
  {
    index: 2,
    name: "Task",
    enabled: true,
    width: 260,
  },
  {
    index: 3,
    name: "Customer",
    enabled: true,
    width: 180,
  },
  {
    index: 4,
    name: "Vendor",
    enabled: true,
    width: 180,
  },
  {
    index: 5,
    name: "Assigned To",
    enabled: true,
    width: 180,
  },
  {
    index: 6,
    name: "Start Date",
    enabled: true,
    width: 120,
  },
  {
    index: 7,
    name: "Due Date",
    enabled: true,
    width: 120,
  },
  {
    index: 8,
    name: "Action",
    enabled: true,
    width: 150,
  },
  {
    index: 9,
    name: headerColumns.LAST_BLANK_COLUMN,
    enabled: true,
    width: 16,
  },
  {
    index: 10,
    name: "Extra",
    enabled: true,
    width: "100%",
  },
];

export const orderByPhasePOSetup = [
  {
    index: 0,
    name: headerColumns.FAVORITE,
    enabled: true,
    unmovable: true,
  },
  {
    index: 1,
    name: headerColumns.DIAMOND,
    enabled: true,
    unmovable: true,
  },
  {
    index: 2,
    name: headerColumns.PO,
    enabled: true,
    unmovable: true,
  },
  {
    index: 3,
    name: headerColumns.VENDOR,
    unmovable: false,
    enabled: true,
  },
  {
    index: 4,
    name: headerColumns.PO_VALUE,
    enabled: true,
  },
  {
    index: 5,
    name: headerColumns.ORDER_READY_DATE,
    enabled: true,
  },
  {
    index: 7,
    name: headerColumns.PROPOSAL_QUOTE,
    enabled: true,
  },
  {
    index: 8,
    name: headerColumns.PRE_PRODUCTION,
    enabled: true,
  },
  {
    index: 9,
    name: headerColumns.PRODUCTION,
    enabled: true,
  },
  {
    index: 10,
    name: headerColumns.BOOKING_TRANSIT,
    enabled: true,
  },
  {
    index: 11,
    name: headerColumns.PAYMENT_BILLING,
    enabled: true,
  },
  {
    index: 12,
    name: "Extra",
    enabled: true,
  },
];

export const orderByPhaseSOSetup = [
  {
    index: 0,
    name: headerColumns.EXPAND,
    enabled: true,
    unmovable: true,
  },
  {
    index: 1,
    name: headerColumns.FAVORITE,
    enabled: true,
    unmovable: true,
  },
  {
    index: 2,
    name: headerColumns.DIAMOND,
    enabled: true,
    unmovable: true,
  },
  {
    index: 3,
    name: headerColumns.SALES_ORDER,
    enabled: true,
    unmovable: false,
  },
  {
    index: 4,
    name: headerColumns.CUSTOMER,
    unmovable: false,
    enabled: true,
  },
  {
    index: 5,
    name: headerColumns.TOTAL,
    enabled: true,
  },
  {
    index: 6,
    name: headerColumns.SO_SHIP_DATE,
    enabled: true,
  },
  {
    index: 7,
    name: headerColumns.POS,
    enabled: true,
  },
  {
    index: 8,
    name: headerColumns.SHIPMENTS,
    enabled: true,
  },
  {
    index: 9,
    name: headerColumns.PROPOSAL_QUOTE,
    enabled: true,
  },
  {
    index: 10,
    name: headerColumns.PRE_PRODUCTION,
    enabled: true,
  },
  {
    index: 11,
    name: headerColumns.PRODUCTION,
    enabled: true,
  },
  {
    index: 12,
    name: headerColumns.BOOKING_TRANSIT,
    enabled: true,
  },
  {
    index: 13,
    name: headerColumns.PAYMENT_BILLING,
    enabled: true,
  },
  {
    index: 14,
    name: "Extra1",
    enabled: true,
  },
  {
    index: 15,
    name: "Extra",
    enabled: true,
  },
];

export const orderByPhasePOGroupBySOSetup = [
  {
    index: 0,
    name: headerColumns.EXPAND,
    enabled: true,
    unmovable: true,
  },
  {
    index: 1,
    name: headerColumns.FAVORITE,
    enabled: true,
    unmovable: true,
  },
  {
    index: 2,
    name: headerColumns.DIAMOND,
    enabled: true,
    unmovable: true,
  },
  {
    index: 3,
    name: headerColumns.PO,
    enabled: true,
    unmovable: false,
  },
  {
    index: 4,
    name: headerColumns.VENDOR,
    unmovable: false,
    enabled: true,
  },
  {
    index: 5,
    name: headerColumns.PO_VALUE,
    enabled: true,
  },
  {
    index: 6,
    name: headerColumns.ORDER_READY_DATE,
    enabled: true,
  },
  {
    index: 8,
    name: headerColumns.POS,
    enabled: true,
  },
  {
    index: 9,
    name: headerColumns.SHIPMENTS,
    enabled: true,
  },
  {
    index: 10,
    name: headerColumns.PROPOSAL_QUOTE,
    enabled: true,
  },
  {
    index: 11,
    name: headerColumns.PRE_PRODUCTION,
    enabled: true,
  },
  {
    index: 12,
    name: headerColumns.PRODUCTION,
    enabled: true,
  },
  {
    index: 13,
    name: headerColumns.BOOKING_TRANSIT,
    enabled: true,
  },
  {
    index: 14,
    name: headerColumns.PAYMENT_BILLING,
    enabled: true,
  },
  {
    index: 15,
    name: headerColumns.EXTRA,
    enabled: true,
  },
];

export const orderByPhaseShipmentGroupBySOSetup = [
  {
    index: 0,
    name: headerColumns.EXPAND,
    enabled: true,
    unmovable: true,
  },
  {
    index: 1,
    name: headerColumns.FAVORITE,
    enabled: true,
    unmovable: true,
  },
  {
    index: 2,
    name: headerColumns.DIAMOND,
    enabled: true,
    unmovable: true,
  },
  {
    index: 3,
    name: headerColumns.SHIPMENT,
    enabled: true,
    unmovable: false,
  },
  {
    index: 4,
    name: headerColumns.FINAL_DESTINATION,
    unmovable: false,
    enabled: true,
  },
  {
    index: 5,
    name: headerColumns.SHIPMENT_VALUE,
    enabled: true,
  },
  {
    index: 6,
    name: headerColumns.SHIP_DATE,
    enabled: true,
  },

  {
    index: 8,
    name: headerColumns.DELIVERY_DATE,
    enabled: true,
  },
  {
    index: 9,
    name: headerColumns.POS,
    enabled: true,
  },
  {
    index: 10,
    name: headerColumns.PROPOSAL_QUOTE,
    enabled: true,
  },
  {
    index: 10,
    name: headerColumns.PRE_PRODUCTION,
    enabled: true,
  },
  {
    index: 12,
    name: headerColumns.PRODUCTION,
    enabled: true,
  },
  {
    index: 13,
    name: headerColumns.BOOKING_TRANSIT,
    enabled: true,
  },
  {
    index: 14,
    name: headerColumns.PAYMENT_BILLING,
    enabled: true,
  },
  {
    index: 15,
    name: headerColumns.EXTRA,
    enabled: true,
  },
];

export const shipmentHeaderSetup = [
  {
    index: 0,
    name: headerColumns.FAVORITE,
    enabled: true,
    unmovable: true,
    width: 40,
  },
  {
    index: 1,
    name: headerColumns.DIAMOND,
    enabled: true,
    unmovable: true,
    width: 50,
  },
  {
    index: 2,
    name: headerColumns.PROGRESS,
    enabled: true,
    unmovable: true,
    width: 35,
  },
  {
    index: 3,
    name: headerColumns.MENTIONS,
    unmovable: true,
    enabled: true,
    width: 65,
  },
  {
    index: 4,
    name: headerColumns.SHIPMENT,
    unmovable: true,
    enabled: true,
    width: 140,
  },
  {
    index: 5,
    name: headerColumns.SALES_ORDER,
    enabled: true,
    width: 205,
  },
  {
    index: 6,
    name: headerColumns.CUSTOMER,
    enabled: true,
    width: 110,
  },
  {
    index: 7,
    name: headerColumns.PO,
    enabled: true,
    width: 320,
  },
  {
    index: 8,
    name: headerColumns.CREATION_DATE,
    enabled: true,
    width: 120,
  },
  {
    index: 9,
    name: headerColumns.SHIP_DATE,
    enabled: true,
    width: 120,
  },
  {
    index: 10,
    name: headerColumns.DELIVERY_DATE,
    enabled: true,
    width: 120,
  },
  {
    index: 11,
    name: headerColumns.CBM,
    enabled: true,
    width: 100,
  },
  {
    index: 12,
    name: headerColumns.BOOKING,
    enabled: true,
    width: 190,
  },
  {
    index: 13,
    name: headerColumns.BOL,
    enabled: true,
    width: 190,
  },
  {
    index: 14,
    name: headerColumns.CONTAINER,
    enabled: true,
    width: 135,
  },
  {
    index: 15,
    name: headerColumns.SHIPPING_TERMS,
    enabled: true,
    width: 130,
  },
  {
    index: 16,
    name: headerColumns.TOTAL_VALUE,
    enabled: true,
    width: 150,
  },
  {
    index: 17,
    name: headerColumns.POTENTIAL_LATE_FEE,
    enabled: true,
    width: 160,
  },
  {
    index: 18,
    name: headerColumns.CUSTOMER_PO,
    enabled: true,
    width: 150,
  },
  {
    index: 19,
    name: headerColumns.LOADING_PORT,
    enabled: true,
    width: 150,
  },
  {
    index: 20,
    name: headerColumns.UNLOADING_PORT,
    enabled: true,
    width: 150,
  },
  {
    index: 21,
    name: headerColumns.FINAL_DESTINATION,
    enabled: true,
    width: 150,
  },
  {
    index: 22,
    name: headerColumns.UNIQUE_ITEMS,
    enabled: true,
    width: 95,
  },
  {
    index: 23,
    name: headerColumns.FREIGHT_FORWARDER,
    enabled: true,
    width: 120,
  },
  {
    index: 24,
    name: headerColumns.SALES_INVOICE,
    enabled: true,
    width: 135,
  },
  {
    index: 25,
    name: headerColumns.TOTAL_WEIGHT,
    enabled: true,
    width: 130,
  },
  {
    index: 26,
    name: headerColumns.LAST_BLANK_COLUMN,
    enabled: true,
    width: 16,
  },
  {
    index: 27,
    name: headerColumns.EXTRA,
    enabled: true,
    width: "100%",
  },
];

export const expensesHeaderSetup = [
  {
    index: 0,
    name: headerColumns.FAVORITE,
    enabled: true,
    unmovable: true,
    width: 35,
  },
  {
    index: 1,
    name: headerColumns.DOCUMENT_DATE,
    enabled: true,
    unmovable: true,
    width: 115,
  },
  {
    index: 2,
    name: headerColumns.PAID_TO,
    enabled: true,
    unmovable: true,
    width: 210,
  },
  {
    index: 3,
    name: headerColumns.ITEM_TYPE,
    enabled: true,
    unmovable: false,
    width: 160,
  },
  {
    index: 4,
    name: headerColumns.ORDER_NUMBER,
    enabled: true,
    unmovable: false,
    width: 185,
  },
  {
    index: 5,
    name: headerColumns.GL_ACCOUNT,
    enabled: true,
    unmovable: false,
    width: 170,
  },
  {
    index: 6,
    name: headerColumns.DESCRIPTION,
    enabled: true,
    unmovable: false,
    width: 170,
  },
  {
    index: 7,
    name: headerColumns.NOTES,
    enabled: true,
    unmovable: false,
    width: 150,
  },
  {
    index: 8,
    name: headerColumns.FORECAST,
    enabled: true,
    unmovable: false,
    width: 160,
  },
  {
    index: 9,
    name: headerColumns.ACTUAL,
    enabled: true,
    unmovable: false,
    width: 160,
  },
  {
    index: 10,
    name: headerColumns.DELTA,
    enabled: true,
    unmovable: false,
    width: 110,
  },
  {
    index: 11,
    name: headerColumns.CUSTOMER,
    enabled: true,
    unmovable: false,
    width: 120,
  },
  {
    index: 12,
    name: headerColumns.CUSTOMER_PO,
    enabled: true,
    unmovable: false,
    width: 110,
  },
  {
    index: 13,
    name: headerColumns.REFERENCE_NUMBER,
    enabled: true,
    unmovable: false,
    width: 120,
  },
  {
    index: 14,
    name: headerColumns.CREATION_DATE,
    enabled: true,
    unmovable: false,
    width: 100,
  },
  {
    index: 15,
    name: headerColumns.EXPORTED_DATE,
    enabled: true,
    unmovable: false,
    width: 105,
  },
  {
    index: 16,
    name: headerColumns.ED_TOOL,
    enabled: true,
    unmovable: true,
    width: 110,
  },
];

export const columnHeaderSetup = {
  PO_HEADER_SETUP: "POHeaderSetup",
  SO_HEADER_SETUP: "SOHeaderSetup",
  TASK_HEADER_SETUP: "taskHeaderSetup",
  ORDER_BY_PHASE_PO_SETUP: "orderByPhasePOSetup",
  ORDER_BY_PHASE_SO_SETUP: "orderByPhaseSOSetup",
  ORDER_BY_PHASE_PO_GROUP_BY_SO_SETUP: "orderByPhasePOGroupBySOSetup",
  SHIPMENT_HEADER_SETUP: "shipmentHeaderSetup",
  EXPENSES_HEADER_SETUP: "expensesHeaderSetup",
};
