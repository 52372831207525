import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import TagsModal from "../../components/Modal/TagsModal";
import TooltipTD from "../../components/Tooltip/TooltipTD";
import {
  getProgressValue,
  getDashboardSearchPath,
  getTagsLabel,
  sortObjectsBy,
  verifyVendorIcon,
} from "../../helpers/helpers";

import FactoryIcon from "../../assets/flag-icons/sidebar-factories.svg";
import { dbTables } from "../../api/types/dbTables";
import {
  GENERAL_PERMISSION_VALUE,
  PERMISSION_TEXT,
  SALES_ORDER_STATUS,
} from "../../helpers/constants";
import { colors } from "../../assets/jss/variables";
import { getTagStatus } from "../../helpers/salesOrder";
import { verifyPermission } from "../../hooks/permissions";
import HiddenDataIconAnimated from "../../helpers/iconAnimation/HiddenDataIconAnimated";
import { ORDER_DASHBOARD_ACTUAL_VIEWS } from "../../components/PurchaseOrderDashboard/sections/helpers";
import { CircularStaticProgress } from "../../components/CircularProgress/CircularStaticProgress";
import { AnimatedListStyled } from "../StyledComponent/AnimatedOpenList";
import ThreePanelButton from "../../components/Buttons/ThreePanelButton";
import SalesOrderBadge from "../../components/Badge/SalesOrderBadge";
import AttentionModal from "../../components/Modal/AttentionModal";

function SalesOrdersItem({
  item,
  user,
  onUpdateItem,
  isTradeDashEmployee,
  filters,
  poProgress,
  poDiamonds,
  tags,
  isAllowed,
  salesOrderSetup = {},
  onChangeSalesOrderSetup = () => {},
  iconStyle = {},
  query = "",
}) {
  const [openAttentionModal, setOpenAttentionModal] = useState(false);
  const { tag: filterTag = [] } = filters;
  const salesOrders =
    item.salesOrders || [].sort(sortObjectsBy("number", false));

  let hasTag = false;
  if (
    !(salesOrderSetup[item.id] && salesOrderSetup[item.id].SOs) ||
    salesOrders.length <= 1
  ) {
    salesOrders.forEach((so) => {
      const soTags = so.tags || [];
      hasTag = soTags.some((tag) => filterTag.includes(tag)) || hasTag;
    });
  }

  const isOpen =
    (salesOrderSetup[item.id] && salesOrderSetup[item.id].SOs) ||
    salesOrders.length <= 1;

  const showIconDisclouser = () => {
    if (salesOrders.length > 0) {
      return (
        <div
          className="iconAnimated"
          onClick={() => {
            onChangeSalesOrderSetup({
              ...salesOrderSetup,
              [item.id]: {
                ...salesOrderSetup[item.id],
                POs: !salesOrderSetup[item.id]
                  ? true
                  : !salesOrderSetup[item.id].POs,
              },
            });
          }}
          style={{
            opacity: !isOpen ? 1 : 0,
            zIndex: !isOpen ? 1 : 0,
            ...iconStyle,
            cursor: "pointer",
            pointerEvents: !isOpen ? "" : "none",
          }}
        >
          {HiddenDataIconAnimated({
            styles: {
              display: "flex",
            },
            color: hasTag ? colors.diamondRed : "",
            showHighlight: query
              ? salesOrders.some((so) =>
                  `${so.number}`.toLowerCase().includes(query.toLowerCase())
                )
              : false,
          })}

          <span className="animateText">{salesOrders.length}</span>
        </div>
      );
    }
  };

  const showItems = () => {
    return salesOrders.map((salesOrder, index) => {
      const purchaseOrderIds = item.poIds || salesOrder.purchaseOrderIds || [];
      const purchaseOrders = item.purchaseOrders || [];
      const firstPOId = purchaseOrderIds.find((id) =>
        purchaseOrders.find((po) => po.id === id)
      );
      const showIconVendor = verifyVendorIcon(filters, salesOrder);
      const salesOrderTag = salesOrder.tags || [];
      const shipmentId =
        item.poShipmentId !== undefined ? item.poShipmentId : item.id;
      const isAvalible = verifyPermission({
        user,
        permissionToCheck: [
          item.customerId,
          GENERAL_PERMISSION_VALUE.ALL_CUSTOMERS,
        ],
      });
      const showTag = isAllowed("see_tags") && isAvalible;

      return (
        <div
          key={salesOrder.id + index}
          style={{
            display: "flex",
          }}
          className="orderList"
        >
          <div
            className="po-progress-diamonds-container"
            style={!poProgress && !poDiamonds ? { width: "0%" } : {}}
          >
            {poProgress && salesOrder.status !== SALES_ORDER_STATUS.VOIDED && (
              <div className="progress-container">
                <CircularStaticProgress
                  label={`${salesOrder.completedTasks}/${salesOrder.totalTasks} Tasks`}
                  value={+getProgressValue(salesOrder)}
                  thickness={16}
                  style={{
                    transform: "rotate(270deg)",
                    color: "#39F",
                  }}
                  size={15}
                />
              </div>
            )}
          </div>
          <div
            style={{
              width: !poProgress && !poDiamonds ? "100%" : "75%",
              display: "flex",
              justifyContent: " space-between",
            }}
          >
            <div className={"number-table-container"}>
              {showTag && (
                <TagsModal
                  key={salesOrder.id}
                  label={getTagsLabel(tags, salesOrderTag).tagsString}
                  tags={getTagsLabel(tags, salesOrderTag).tagsArray}
                  allTags={tags}
                  item={salesOrder}
                  currentUser={user}
                  tagReference={dbTables.SALES_ORDERS}
                  onUpdateItem={onUpdateItem}
                  isThereItemRef={false}
                  isReadOnly={isTradeDashEmployee(user)}
                  status={getTagStatus({
                    tags: salesOrderTag,
                    currentTags: filterTag,
                  })}
                />
              )}
              {!showTag && <span style={{ width: 18, minWidth: 18 }} />}
              {showIconVendor && (
                <TooltipTD label={salesOrder.factoryName}>
                  <img
                    src={FactoryIcon}
                    alt={"FactoryIcon"}
                    style={{
                      width: 11,
                      marginRight: 5,
                      marginBottom: 5,
                    }}
                  />
                </TooltipTD>
              )}

              <NavLink
                to={
                  isAvalible
                    ? getDashboardSearchPath({
                        salesOrderId: salesOrder.id,
                        purchaseOrderId: firstPOId,
                        shipmentId: shipmentId,
                        section: ORDER_DASHBOARD_ACTUAL_VIEWS.SALES_ORDER,
                      })
                    : "#"
                }
                id="list-view-content-salesorder"
                className={"navLink"}
                style={{
                  textDecoration: "none",
                }}
                onClick={(ev) => {
                  if (!isAvalible) {
                    setOpenAttentionModal(true);
                    ev.preventDefault();
                    ev.stopPropagation();
                  }
                }}
              >
                <SalesOrderBadge
                  number={salesOrder.number}
                  isDisable={!isAvalible}
                />
              </NavLink>
            </div>
            {isAvalible && (
              <ThreePanelButton
                soId={salesOrder.id}
                poId={firstPOId}
                shipmentId={shipmentId}
              />
            )}
          </div>
        </div>
      );
    });
  };

  return (
    <>
      {openAttentionModal && (
        <AttentionModal
          isOpen={openAttentionModal}
          title={"Attention"}
          description={PERMISSION_TEXT.CUSTOMER}
          onClick={() => {
            setOpenAttentionModal(false);
          }}
        />
      )}
      <AnimatedListStyled
        className="listContainer"
        style={{
          height: isOpen
            ? salesOrders.length * 22.1 + (salesOrders.length - 1) * 5
            : 24,
        }}
      >
        <div
          className="displayItems"
          style={{
            opacity: isOpen ? 1 : 0,
            zIndex: isOpen ? 1 : 0,
            pointerEvents: isOpen ? "" : "none",
          }}
        >
          {showItems()}
        </div>
        {showIconDisclouser()}
      </AnimatedListStyled>
    </>
  );
}

export default SalesOrdersItem;
