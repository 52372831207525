import React, { useState, useEffect, useRef, useCallback } from "react";
import { ShipmentListContainer, ShipmentTableContainer } from "./styles";
import GeneralFilters from "../../components/FilterTable/GeneralFilters";
import ButtonFilter, {
  BUTTON_FILTER_BOX_SHADOW,
} from "../../components/PurchaseOrderList/ButtonFilter";
import {
  useCompanyId,
  useJSONLocalStorage,
  useFavorites,
  useStorage,
  useFavoritesList,
} from "../../hooks";
import { dbTables, USER_CUSTOM_SETTINGS } from "../../api/types/dbTables";
import {
  debounce,
  getMaxDate,
  getScreenListGrid,
  getTheDate,
  sortObjectsBy,
} from "../../helpers/helpers";
import moment from "moment";
import { useIsAllowedFunction } from "../../hooks/permissions";
import SearchBox from "../../components/SearchBox/SearchBox";
import FilterDisplay from "../../components/PurchaseOrderList/FilterDisplay";
import numeral from "numeral";
import FooterTable from "../../components/Tables/FooterTable";
import { footerShipmentsScreen } from "../../components/Managers/FooterTableManager";
import { shipmentsHeaderCells } from "../../components/Managers/TableHeaderProvider";
import {
  getListValuesFromArray,
  getScreenTraceName,
  getToolTipArrayValue,
} from "../../helpers/purchaseOrderList";
import MainTableRefactor from "../../components/filterTableRefactor/MainTableRefactor";
import BasicPagination from "../../components/Tables/BasicPagination";
import {
  AUTOMATIC_SYSTEM_LABEL,
  excludeElementsScreen,
  generalPermissions,
  headerColumns,
  LIST_VIEW_BUTTONS_GRID,
} from "../../helpers/constants";
import { columnHeaderSetup } from "../../helpers/screenHeaderSetup";
import {
  useUser,
  useUserCustomSetting,
  useUserMentions,
} from "../../hooks/user";
import { useCustomers } from "../../hooks/customers";
import { useFactories } from "../../hooks/factories";
import { useTags } from "../../hooks/tags";
import { getStatusAndDiamondStatus } from "../../helpers/screens";
import { performanceFirebase } from "../../firebase";
import { getUserAvatar } from "../../helpers/users";
import AttentionModal from "../../components/Modal/AttentionModal";
import { filterList, SCREEN_TYPE } from "../../helpers/salesOrderHelpers";
import { parseNumberValue } from "../../helpers/orderDashboardRefactored";
import { trace } from "firebase/performance";
import { getFunctions, httpsCallableFromURL } from "firebase/functions";
import {
  getFunctionByName,
  globalEnvironment,
} from "../../constants/globalVariables";
import StatusOrderButtons from "../../components/PurchaseOrderList/StatusOrderButtons";
import { orderType } from "../../helpers/salesOrder";
import ToolsButtonScreen from "../../components/PurchaseOrderList/ToolsButtonScreen";
import Mark from "mark.js";
import ShipmentsTableRow from "./ShipmentTableRow";
import { useMediaQuery } from "@mui/material";
import { isEqual } from "lodash";
import LoadingBackdrop from "../../components/WholeScreenFocusBackdrop/LoadingBackdrop";
import { StarIcon } from "../../helpers/iconSvgPath";
import { ButtonReset } from "../StyledComponent/ButtonReset";
import DateRangePicker from "../../components/DatePicker/DateRangePicker";
import LateNearDueDiamondButtons from "../../components/PurchaseOrderList/LateNearDueDiamondButtons";
import { updateColumnHeaders } from "../../helpers/tasks";
import ColumnHeader from "../../api/model/ColumnHeader.model";

const dateFields = ["Created Date", "Delivery Date", "Ship Date"];
const hiddenStyle = {
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  display: "block",
  overflow: "hidden",
};
const BODY_ID = "shipmentOrderTableRow";

function ShipmentsScreen() {
  const dateRef = useRef(null);
  const { get, set } = useJSONLocalStorage("shipmentListFilters");
  const dataLocalStorage = get() || {};
  const user = useUser();
  const matches = useMediaQuery("(max-width:1460px)");
  const userCustomSettings = useUserCustomSetting({
    userId: user.id,
    setting: USER_CUSTOM_SETTINGS.SCREEN_HEADERS,
  });
  const shipmentUserMention = useUserMentions(dbTables.SHIPMENTS);
  const companyId = useCompanyId();
  const favoritesDB = useFavorites({ dbTable: dbTables.SHIPMENT_FAVORITES });
  const favorites = useFavoritesList({ dbTable: dbTables.SHIPMENT_FAVORITES });
  const [loading, setLoading] = useState(false);
  const isAllowed = useIsAllowedFunction();
  const customers = useCustomers();
  const factories = useFactories();
  const tags = useTags();
  const [filters, setFilters] = useState({
    factory: dataLocalStorage.factory || [],
    customer: dataLocalStorage.customer || [],
    tag: dataLocalStorage.tag || [],
    dateRange: dataLocalStorage.dateRange || "",
    date: dataLocalStorage.date || "",
    inProgress: !get() ? true : dataLocalStorage.inProgress || false,
    completed: !!dataLocalStorage.completed,
    voided: !!dataLocalStorage.voided,
    late: !!dataLocalStorage.late,
    nearDue: !!dataLocalStorage.nearDue,
  });
  const [openList, setOpenList] = useState(false);
  const [callFunction, setCallFunction] = useState(true);
  const [previousFilter, setPreviousFilter] = useState({});
  const [nonLookUpfilters, setNonLookUpFilters] = useState({
    favorite: !!dataLocalStorage.favorite,
    query: dataLocalStorage.query || "",
    sortedColumn: dataLocalStorage.sortedColumn || "",
    orderBy: dataLocalStorage.orderBy || "",
  });
  const [shipments, setShipments] = useState([]);
  const [dataFiltered, setDataFiltered] = useState({
    shipments: [],
    totalAmounts: {},
  });
  const [shipmentStorageSetup, setShipmentStorageSetup] =
    useStorage("shipmentScreen");
  const [shipmentScreenSetup, setShipmentScreenSetup] =
    useState(shipmentStorageSetup);
  const [firstCall, setFirstCall] = useState(false);
  const [openDate, setOpenDate] = useState(false);
  const [existsDate, setExistsDate] = useState(dataLocalStorage.date || false);
  const [filterDate, setFilterDate] = useState(dataLocalStorage.date || "");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(
    dataLocalStorage.rowsPerPage || 10
  );
  const [headerCells, setHeaderCells] = useState([]);
  const [currentFavorites, setCurrentFavorites] = useState([]);
  const [openAttentionModal, setOpenAttentionModal] = useState(false);
  const onDebounce = useCallback(
    debounce(() => {
      setCallFunction(true);
    }, 1250),
    []
  );

  function changeFilters(newFilters) {
    if (!isEqual(filters, newFilters)) {
      onDebounce();
      setFilters({ ...newFilters });
    }
  }

  const handleSetupStorage = ({ item, poValue, soValue }) => {
    const itemStorage = shipmentScreenSetup[item.id] || {};
    const poSetup = poValue !== undefined ? poValue : itemStorage.POs;
    const soSetup = soValue !== undefined ? soValue : itemStorage.SOs;
    setShipmentScreenSetup({
      ...shipmentScreenSetup,
      [item.id]: {
        POs: poSetup,
        SOs: soSetup,
      },
    });
    setShipmentStorageSetup(item.id, {
      SOs: soValue,
      POs: poValue,
    });
  };

  async function getShipments() {
    setLoading(true);
    setPreviousFilter(filters);
    if (!filters.inProgress && !filters.completed && !filters.voided) {
      setShipments([]);
      setLoading(false);
      return;
    }
    const traceName = getScreenTraceName({
      filters,
      scope: dbTables.SHIPMENTS,
    });
    const traceInstance = trace(performanceFirebase, traceName);
    if (traceName) {
      traceInstance.start();
    }
    const { status, diamondStatus } = getStatusAndDiamondStatus({ filters });
    try {
      setFirstCall(true);
      const functions = getFunctions();
      const shipmentEndpoint = httpsCallableFromURL(
        functions,
        getFunctionByName({
          name: "listview",
          env: globalEnvironment,
          params: "/shipments",
        })
      );
      shipmentEndpoint({
        filters: {
          ...filters,
          status,
          diamondStatus,
          dateRange: "",
          dateStart: filters.dateRange
            ? moment(filters.dateRange.start).valueOf()
            : "",
          dateEnd: filters.dateRange
            ? moment(filters.dateRange.end).endOf("day").valueOf()
            : "",
        },
        companyId,
        userId: user.id,
      }).then((result) => {
        const { shipmentsDB } = result.data;
        const parseShipmentData = shipmentsDB.map((shipment) => ({
          ...shipment,
          shipDate: moment(shipment.shipDate).valueOf(),
          deliveryDate: moment(shipment.deliveryDate).valueOf(),
        }));
        setShipments([...parseShipmentData]);
        if (traceName) {
          traceInstance.stop();
        }
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      if (traceName) {
        traceInstance.stop();
      }
      setLoading(false);
    }
  }

  useEffect(() => {
    const { filteredList, totalAmounts } = filterList({
      list: shipments,
      favorites,
      headerCells,
      headerColumns,
      nonLookUpfilters,
      screenType: SCREEN_TYPE.SHIPMENT_LIST,
      userMentions: shipmentUserMention,
    });
    setDataFiltered({ shipments: filteredList, totalAmounts });
  }, [headerCells, currentFavorites, shipments]);

  useEffect(() => {
    if (favorites.length !== currentFavorites.length) {
      setCurrentFavorites(favorites);
    }
  }, [favorites]);

  useEffect(() => {
    if (!openList && !isEqual(filters, previousFilter)) {
      onDebounce();
    }
  }, [openList]);

  useEffect(() => {
    getShipments();
    setPage(1);
  }, [companyId]);

  useEffect(() => {
    if (!callFunction) {
      return;
    }
    setCallFunction(false);
    if (openList) {
      return;
    }
    if (
      filters.date &&
      filters.date !== "" &&
      !filters.dateRange.start &&
      !filters.dateRange.end
    ) {
      return;
    }
    if (isEqual(filters, previousFilter)) {
      console.log("same filter");
      return;
    }
    setShipments([]);
    setDataFiltered({ shipments: [], totalAmounts: {} });
    if (firstCall) {
      getShipments();
    }
    set({ ...filters, ...nonLookUpfilters, date: filterDate, rowsPerPage });
    setPage(1);
  }, [callFunction]);

  useEffect(() => {
    set({ ...filters, ...nonLookUpfilters, date: filterDate, rowsPerPage });
  }, [rowsPerPage]);

  useEffect(() => {
    function onAdjustHeader() {
      let userHeaderCell = userCustomSettings.shipmentHeaderSetup || [];
      userHeaderCell = userHeaderCell.map((header) => {
        const shipmentHeader = shipmentsHeaderCells[header.name];
        const headerStyle = shipmentHeader.styles || {};

        if (
          shipmentHeader[generalPermissions.SHIPMENT_AMOUNT] &&
          !isAllowed(generalPermissions.SHIPMENT_AMOUNT)
        ) {
          return {
            ...header,
            ...shipmentHeader,
            styles: {
              ...shipmentHeader.styles,
              width: header.width || 0,
              display: "none",
            },
          };
        } else if (header.name === "mentions") {
          return {
            ...header,
            ...shipmentHeader,
            displayLabel: getUserAvatar({
              user,
              styles: {
                width: 24,
                height: 24,
                fontSize: 13,
                outline: "2px solid #000",
              },
              hasToolTip: false,
            }),
            styles: {
              ...shipmentHeader.styles,
              width: header.width || 0,
            },
          };
        }

        return {
          ...header,
          ...shipmentHeader,
          styles: {
            ...headerStyle,
            width: header.width || 0,
          },
        };
      });
      //

      //
      if (!isEqual(headerCells, userHeaderCell)) {
        setHeaderCells(userHeaderCell);
      }
    }
    onAdjustHeader();
  }, [
    rowsPerPage,
    page,
    nonLookUpfilters,
    shipments,
    isAllowed(generalPermissions.SHIPMENT_AMOUNT),
    userCustomSettings?.shipmentHeaderSetup?.length,
  ]);

  function changeButtonFilter(filter) {
    changeFilters({ ...filters, [filter]: !filters[filter] });
  }

  function changeNonLookUpButtonFilter(filter) {
    setNonLookUpFilters({
      ...nonLookUpfilters,
      [filter]: !nonLookUpfilters[filter],
    });
  }

  useEffect(() => {
    setPage(1);
    set({ ...filters, ...nonLookUpfilters, date: filterDate, rowsPerPage });
    if (!shipments.length) return;
    const { filteredList, totalAmounts } = filterList({
      list: shipments,
      favorites,
      headerCells,
      headerColumns,
      nonLookUpfilters,
      screenType: SCREEN_TYPE.SHIPMENT_LIST,
      userMentions: shipmentUserMention,
    });
    setDataFiltered({ shipments: filteredList, totalAmounts });
  }, [nonLookUpfilters]);

  function resetFilters() {
    setFilters({
      factory: [],
      customer: [],
      dateRange: "",
      date: "",
      inProgress: true,
      completed: false,
      voided: false,
      late: false,
      nearDue: false,
    });
    setNonLookUpFilters({
      ...nonLookUpfilters,
      query: "",
      favorite: false,
    });
    setFilterDate("");
    setExistsDate(false);
    setPage(1);
    onDebounce();
  }

  function changeDate(date) {
    setFilters({
      ...filters,
      dateRange: date,
      date: filterDate,
    });
    setOpenDate(false);
    setOpenList(false);
  }

  function handleCloseDatePicker() {
    setOpenDate(false);
    setOpenList(false);
    if (!filterDate) {
      setFilters({ ...filters, date: "" });
    }
  }

  function onOpenDatePicker(field) {
    setOpenList(true);
    setExistsDate(true);
    setFilterDate(field);
    setFilters({
      ...filters,
      date: field,
      dateRange: { start: "", end: "" },
    });
    setTimeout(() => setOpenDate(true), 50);
  }

  const markInstance = new Mark(document.getElementById(BODY_ID));

  function performMark(keyword = "") {
    var options = {
      separateWordSearch: false,
      diacritics: false,
      debug: false,
      acrossElements: false,
      accuracy: "partially",
      exclude: excludeElementsScreen,
    };
    markInstance.unmark({
      done: () => {
        markInstance.mark(keyword, options);
      },
    });
  }
  useEffect(() => {
    if (!loading) {
      setTimeout(() => performMark(nonLookUpfilters.query), 150);
    }
  }, [nonLookUpfilters, loading, rowsPerPage, page]);

  function areAllSOExpanded({ shipments, shipmentsSetup }) {
    const isThereASectionClosed = shipments.some((shipment) => {
      if (!shipmentsSetup[shipment.id]) {
        return true;
      } else if (
        !shipmentsSetup[shipment.id].POs ||
        !shipmentsSetup[shipment.id].SOs
      ) {
        return true;
      }
      return false;
    });
    return !isThereASectionClosed;
  }

  function handleChangePage(newPage) {
    setPage(newPage);
  }

  function renderFooter(list = [], renderFooterTable, totalAmounts) {
    return (
      <React.Fragment>
        <FooterTable
          footer={footerShipmentsScreen(list.length, totalAmounts, isAllowed)}
          headerCells={headerCells.sort(sortObjectsBy("index", false))}
          isThereColumnSetting={true}
        />
      </React.Fragment>
    );
  }

  function getCSVBody(shipments = []) {
    return shipments.map((item) => ({
      favorite: favorites.map((favoriteId) => favoriteId).includes(item.id)
        ? "YES"
        : "NO",
      status: item.late ? "Red" : item.nearDue ? "Orange" : "",
      progress:
        parseNumberValue(
          (parseNumberValue(item.completedTasks) * 100) /
            parseNumberValue(item.totalTasks) /
            100
        ).toFixed(2) || 0,
      number: item.number,
      salesOrder: item.salesOrders.map((so) => so.number).join(", ") + " \n ",
      customerName: item.customerName,
      purchaseOrder:
        item.purchaseOrders.map((po) => po.number).join(", ") + " \n ",
      creationDate:
        !item.creationDate || item.creationDate === ""
          ? ""
          : moment(item.creationDate).format("M/D/YY"),
      shipDate:
        !item.shipDate || item.shipDate === ""
          ? ""
          : moment(item.shipDate).format("M/D/YY"),
      deliveryDate:
        !item.deliveryDate || item.deliveryDate === ""
          ? ""
          : moment(item.deliveryDate).format("M/D/YY"),
      CBM: numeral(item.CBM).format("0,0.00"),
      booking: item.booking,
      billOfLading: item.billOfLading,
      container: item.container,
      shippingTerms: item.shippingTerms,
      customerPO: item.customerPO,
      total: isAllowed(generalPermissions.SHIPMENT_AMOUNT)
        ? numeral(item.total).format("0,0.00")
        : "",
      potentialLateFee: isAllowed(generalPermissions.SHIPMENT_AMOUNT)
        ? numeral(item.potentialLateFee).format("0,0.00")
        : "",
      totalWeight: isAllowed(generalPermissions.SHIPMENT_AMOUNT)
        ? numeral(item.totalWeight).format("0,0.00")
        : "",
      loadingPort: item.loadingPort,
      unloadingPort: item.unloadingPort,
      finalDestination: item.finalDestination,
      uniqueItems: item.uniqueItems,
      freightForwarder: item.freightForwarder,
      invoice: item.invoice,
      shipmentLabels: getListValuesFromArray({
        arr: item.tags || [],
        list: tags || [],
        field: "name",
      }),
    }));
  }

  function handleUpdateItem(item, tagReference) {
    console.log("HANDLE UPDATE ITEM IN SHIPMENT:: ", item, tagReference);
    let shipmentsCpy = [...shipments];
    if (tagReference === dbTables.SHIPMENTS) {
      shipmentsCpy = shipmentsCpy.map((so) => {
        if (item.id === so.id) {
          return {
            ...item,
          };
        }
        return so;
      });
      setShipments(shipmentsCpy);
      const { filteredList, totalAmounts } = filterList({
        list: shipmentsCpy,
        favorites,
        headerCells,
        headerColumns,
        nonLookUpfilters,
        screenType: SCREEN_TYPE.SHIPMENT_LIST,
        userMentions: shipmentUserMention,
      });
      setDataFiltered({
        shipments: filteredList,
        totalAmounts,
      });
    } else if (tagReference === dbTables.SALES_ORDERS) {
      shipmentsCpy = shipmentsCpy.map((shipment) => {
        return {
          ...shipment,
          salesOrders: shipment.salesOrders.map((salesOrder) => {
            if (salesOrder.id === item.id) {
              return item;
            }
            return salesOrder;
          }),
        };
      });
      setShipments(shipmentsCpy);
      const { filteredList, totalAmounts } = filterList({
        list: shipmentsCpy,
        favorites,
        headerCells,
        headerColumns,
        nonLookUpfilters,
        screenType: SCREEN_TYPE.SHIPMENT_LIST,
        userMentions: shipmentUserMention,
      });
      setDataFiltered({
        shipments: filteredList,
        totalAmounts,
      });
    } else {
      // UPDATE PO TAG LABEL
      shipmentsCpy = shipmentsCpy.map((shipment) => {
        return {
          ...shipment,
          purchaseOrders: shipment.purchaseOrders.map((purchaseOrder) => {
            if (purchaseOrder.id === item.id) {
              return item;
            }
            return purchaseOrder;
          }),
        };
      });
      setShipments(shipmentsCpy);
      const { filteredList, totalAmounts } = filterList({
        list: shipmentsCpy,
        favorites,
        headerCells,
        headerColumns,
        nonLookUpfilters,
        screenType: SCREEN_TYPE.SHIPMENT_LIST,
        userMentions: shipmentUserMention,
      });
      setDataFiltered({
        shipments: filteredList,
        totalAmounts,
      });
    }
  }

  const filterFactoryActive = filters.factory?.length > 0;
  const filterTagActive = filters.tag && filters.tag?.length > 0;
  const filterCustomerActive =
    isAllowed("see_tags") && filters.customer?.length > 0;

  const clearFilterData = ({ filterKey }) => {
    setFilters({ ...filters, [filterKey]: [] });
    onDebounce();
  };

  return (
    <React.Fragment>
      {openAttentionModal && (
        <AttentionModal
          isOpen={openAttentionModal}
          description={
            <React.Fragment>
              You do not have access to the shipment documents folder for this
              shipment
            </React.Fragment>
          }
          onClose={() => setOpenAttentionModal(false)}
          onClick={() => setOpenAttentionModal(false)}
        />
      )}
      {loading && (
        <LoadingBackdrop
          size={60}
          backdropStyle={{
            position: "fixed",
          }}
          withLogo={true}
        />
      )}
      <ShipmentListContainer className="root">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <GeneralFilters
            onChange={changeFilters}
            dateFields={dateFields}
            currentFilters={filters}
            onOpenDatePicker={onOpenDatePicker}
            handleListOpen={(value) => {
              setTimeout(() => setOpenList(value), value ? 200 : 50);
            }}
            enabledFields={{
              customer: true,
              factory: true,
              date: true,
              tag: true,
            }}
            tagFilters={[
              AUTOMATIC_SYSTEM_LABEL.SALES_ORDER_FILE,
              AUTOMATIC_SYSTEM_LABEL.PURCHASE_ORDER_FILE,
              AUTOMATIC_SYSTEM_LABEL.SALES_ORDER_MISMATCH,
              AUTOMATIC_SYSTEM_LABEL.SHIPMENT_MISMATCH,
            ]}
            filterData="name"
          />
          <SearchBox
            placeholder="Search for shipment or PO, Sales Order, BOL, Container, Etc."
            value={nonLookUpfilters.query}
            hasBeenReseted={(nonLookUpfilters) => {
              setNonLookUpFilters({ ...nonLookUpfilters, query: "" });
            }}
            filters={nonLookUpfilters}
            onDebounceValue={(nonLookUpfilters, value) => {
              setNonLookUpFilters({ ...nonLookUpfilters, query: value });
            }}
          />
        </div>
        <div className="contentContainer">
          <div
            className="buttonsFilter"
            style={{
              gridTemplateColumns: getScreenListGrid({
                grid: LIST_VIEW_BUTTONS_GRID.SO,
                filtersActive: [
                  filterCustomerActive,
                  filterFactoryActive,
                  filterTagActive,
                ],
              }),
            }}
          >
            <ButtonFilter
              buttonId="list-view-button-filter-favorite"
              value={nonLookUpfilters.favorite}
              onClick={() => changeNonLookUpButtonFilter("favorite")}
              boxShadowType={BUTTON_FILTER_BOX_SHADOW.FAVORITE}
              className="buttonStar"
            >
              <StarIcon />
            </ButtonFilter>
            <LateNearDueDiamondButtons
              filters={filters}
              changeButtonFilter={changeButtonFilter}
            />
            <ButtonFilter
              buttonId="list-view-button-filter-useronly"
              value={nonLookUpfilters.user}
              onClick={() => changeNonLookUpButtonFilter("user")}
              toolTip="only"
              className="onlyAvatar"
            >
              {getUserAvatar({
                user,
                hasToolTip: false,
                styles: {
                  width: 24,
                  height: 24,
                  marginRight: 5,
                  fontSize: 13,
                  outline: "2px solid #000",
                },
              })}
              <span className="onlyText"> Only</span>
            </ButtonFilter>
            <StatusOrderButtons
              changeButtonFilter={changeButtonFilter}
              filters={{ ...filters, existsDate }}
            />

            {filterCustomerActive && (
              <FilterDisplay
                clearIconId="list-view-filter-badge-clear-customer"
                onClear={() => clearFilterData({ filterKey: "customer" })}
                label={filters.customer.length === 1 ? "Customer" : "Customers"}
                labelStyle={hiddenStyle}
                tooltip={
                  getToolTipArrayValue(filters.customer, customers).tooltip
                }
                value={getToolTipArrayValue(filters.customer, customers).value}
              />
            )}
            {filterFactoryActive && (
              <FilterDisplay
                clearIconId="list-view-filter-badge-clear-factory"
                onClear={() => clearFilterData({ filterKey: "factory" })}
                label={filters.factory.length === 1 ? "Vendor" : "Vendors"}
                tooltip={
                  getToolTipArrayValue(filters.factory, factories).tooltip
                }
                value={getToolTipArrayValue(filters.factory, factories).value}
              />
            )}
            {filterTagActive && (
              <FilterDisplay
                clearIconId="list-view-filter-badge-clear-label"
                onClear={() => clearFilterData({ filterKey: "tag" })}
                label={filters.tag.length === 1 ? "Label" : "Labels"}
                tooltip={getToolTipArrayValue(filters.tag, tags).tooltip}
                value={tags && getToolTipArrayValue(filters.tag, tags).value}
              />
            )}

            {existsDate && (
              <>
                <div
                  onClick={() => {
                    setOpenDate(true);
                    setOpenList(true);
                  }}
                  ref={dateRef}
                >
                  <FilterDisplay
                    clearIconId="list-view-filter-badge-clear-date"
                    onClear={() => {
                      setFilterDate("");
                      setExistsDate(false);
                      setFilters({
                        ...filters,
                        date: "",
                        dateRange: { start: new Date(), end: new Date() },
                      });
                      setOpenDate(false);
                      setOpenList(false);
                      onDebounce();
                    }}
                    label={filters.date}
                    labelStyle={hiddenStyle}
                    tooltip={
                      filters.dateRange
                        ? `${getTheDate(
                            filters.dateRange.start
                          )} - ${getTheDate(filters.dateRange.end)}`
                        : "Pick a date"
                    }
                    value={
                      filters.dateRange
                        ? `${getTheDate(
                            filters.dateRange.start
                          )} - ${getTheDate(filters.dateRange.end)}`
                        : "Pick a date"
                    }
                  />
                </div>
                {openDate && (
                  <DateRangePicker
                    open={openDate}
                    el={dateRef.current}
                    onClose={handleCloseDatePicker}
                    onChange={changeDate}
                    value={filters.dateRange}
                    cancelIcon
                    maxDate={getMaxDate(filters.date)}
                  />
                )}
              </>
            )}

            <ButtonReset
              id="list-view-button-filter-reset"
              className="root"
              onClick={resetFilters}
            >
              {matches ? "RESET" : "RESET FILTERS"}
            </ButtonReset>
          </div>
          <ToolsButtonScreen
            typeSetup={columnHeaderSetup.SHIPMENT_HEADER_SETUP}
            nonLookUpfilters={nonLookUpfilters}
            changeNonLookUpButtonFilter={changeNonLookUpButtonFilter}
            headerCells={headerCells}
            csvData={getCSVBody(dataFiltered.shipments)}
            onChangeHeader={(newItems) => setHeaderCells(newItems)}
            hiddenButton={{
              [orderType.PURCHASE_ORDER]: true,
              [orderType.SHIPMENT]: true,
            }}
            hiddenDataAction={(value) => {
              let newShipmentSetup = {};
              shipments.forEach((shipment) => {
                newShipmentSetup[shipment.id] =
                  value === true
                    ? undefined
                    : {
                        POs: !value,
                        SOs: !value,
                      };
              });
              setShipmentStorageSetup("", { ...newShipmentSetup }, true);
              setShipmentScreenSetup({ ...newShipmentSetup });
            }}
            isAllExpanded={areAllSOExpanded({
              shipments,
              shipmentsSetup: shipmentStorageSetup,
            })}
            onSaveHeaders={async (newItems) => {
              setLoading(true);
              const successfullySaved = await updateColumnHeaders({
                header: columnHeaderSetup.SHIPMENT_HEADER_SETUP,
                columnHeaders: newItems,
                user: user,
              });
              if (successfullySaved) {
                setLoading(false);
              }
            }}
          />
          <ShipmentTableContainer className="contentListShipment">
            <div className="tableContainer" id={BODY_ID}>
              <MainTableRefactor
                emptyFilter={
                  !filters.inProgress && !filters.completed && !filters.voided
                }
                onChange={(newItems) => {
                  setHeaderCells(newItems);
                }}
                headCells={headerCells.sort(sortObjectsBy("index", false))}
                bodyRows={dataFiltered.shipments.slice(
                  (page - 1) * rowsPerPage,
                  page * rowsPerPage
                )}
                renderRow={(item, index) => {
                  return (
                    <ShipmentsTableRow
                      key={item.id + index}
                      item={item}
                      favorites={favorites}
                      user={user}
                      isAllowed={isAllowed}
                      customers={customers}
                      headers={headerCells.sort(sortObjectsBy("index", false))}
                      tags={tags}
                      onUpdateItem={handleUpdateItem}
                      isThereColumnSetting={true}
                      filters={filters}
                      onChangeSetupStorage={handleSetupStorage}
                      shipmentScreenSetup={shipmentScreenSetup}
                      favoritesDB={favoritesDB}
                      nonLookUpfilters={nonLookUpfilters}
                    />
                  );
                }}
                footer={renderFooter(
                  dataFiltered.shipments,
                  true,
                  dataFiltered.totalAmounts
                )}
                filters={nonLookUpfilters}
                onUpdateFilters={(nonLookUpfilters) => {
                  setPage(1);
                  setNonLookUpFilters({ ...nonLookUpfilters });
                }}
                onResizeColumn={async ({ cell, offset }) => {
                  setLoading(true);
                  let newHeaderCells = [...headerCells];
                  newHeaderCells = newHeaderCells.map((headerCell) => {
                    if (headerCell.name === cell.name) {
                      return {
                        ...headerCell,
                        styles: {
                          ...headerCell.styles,
                          width: offset,
                        },
                      };
                    }
                    return headerCell;
                  });
                  let newItemsCpy = [...newHeaderCells];
                  newItemsCpy = newItemsCpy.map((item) => ({
                    ...new ColumnHeader({
                      enabled: item.enabled,
                      index: item.index,
                      name: item.name,
                      unmovable: item.unmovable || false,
                      width: item.styles.width,
                    }),
                  }));
                  setHeaderCells(newHeaderCells);
                  const successfullySaved = await updateColumnHeaders({
                    header: columnHeaderSetup.SHIPMENT_HEADER_SETUP,
                    columnHeaders: newItemsCpy,
                    user,
                  });
                  if (successfullySaved) {
                    setLoading(false);
                  }
                }}
              />
            </div>
            <BasicPagination
              rowsPerPage={rowsPerPage}
              page={page}
              count={getCountPagination(dataFiltered.shipments, rowsPerPage)}
              onChangePagination={(event, newPage) => {
                handleChangePage(newPage);
              }}
              onChangeRowsPerPage={(event) => {
                setRowsPerPage(+event.target.value);
                setPage(1);
              }}
            />
          </ShipmentTableContainer>
        </div>
      </ShipmentListContainer>
    </React.Fragment>
  );

  function getCountPagination(shipments, rowsPerPage) {
    return Math.ceil(shipments.length / rowsPerPage);
  }
}

export default ShipmentsScreen;
